import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import { generateKey, getMarks, isHyperlinkInternal } from './utils';

const RenderHyperlink = ({
  item,
  variant,
  linkIndex
}) => {
  const [underline, setUnderline] = useState('always');
  const [color, setColor] = useState('subtle');
  if (item.content.length === 0) {
    return null;
  }
  const url = item.data.uri;

  const mainProps = {
    component: 'a',
    variant,
    color,
    underline,
    href: url,
    target: isHyperlinkInternal(url) ? '_self' : '_blank'
  };

  return (
    <>
      {item.content.map((content, i) => {
        const value = getMarks(content.marks).isItalic ? (<i>{content.value}</i>) : content.value;
        return (
          <div
            onMouseEnter={
              () => {
                setColor(null);
                setUnderline('none');
              }
            }
            onMouseLeave={
              () => {
                setColor('subtle');
                setUnderline('always');
              }
            }
          >
            <Link
              key={generateKey(content.value, `${i}.${linkIndex}`, variant)}
              weight={getMarks(content.marks).isBold ? 'bold' : 'regular'}
              {...mainProps} // eslint-disable-line
            >
              {value}
            </Link>
          </div>
        );
      })}
    </>
  );
};

RenderHyperlink.propTypes = {
  item: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.shape({
      uri: PropTypes.string
    }),
  }).isRequired,
  variant: PropTypes.string.isRequired,
  linkIndex: PropTypes.number.isRequired,
};

export { RenderHyperlink };
