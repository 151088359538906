import React from 'react';
import { useHelmet, MetaTag } from '@thd-nucleus/thd-helmet';

const noIndexHandler = () => {

  return new MetaTag({ name: 'ROBOTS', content: 'NOINDEX', id: 'robots' });
};

const NoIndexMetadata = () => {

  useHelmet('noIndexMetatag', {}, noIndexHandler, []);

  return null;
};

NoIndexMetadata.displayName = 'NoIndexMetadata';

NoIndexMetadata.propTypes = {
};

export { NoIndexMetadata };