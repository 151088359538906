import { useContext } from 'react';
import { string } from 'prop-types';
import { RedirectTag, useHelmet } from '@thd-nucleus/thd-helmet';
import {
  params,
  shape,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { RedirectContext } from './RedirectContext';
import { isLandingPageCanonical, isNotFound } from './rules/landing-page-redirect-rules';
import { handleErrors, setUrlContext } from '../utils/redirector-utils';

const landingPageHandler = ({ data, error, props }) => {

  let [path, queryParams = ''] = props.pathWithQueryParams?.split('?') || '';

  path = setUrlContext(path, '/c/');

  const debug = queryParams.indexOf('debug') > -1;

  const errors = handleErrors(error);
  if (errors) {
    return new RedirectTag(errors, props.bypass);
  }
  const notFound = isNotFound({ data });
  if (notFound) {
    return new RedirectTag(notFound, props.bypass);
  }

  const landingPageCanonical = isLandingPageCanonical({ data, path });
  if (landingPageCanonical && !debug) {
    return new RedirectTag(landingPageCanonical, props.bypass);
  }
  return null;
};

export const LandingPageRedirector = ({ customerType, slug }) => {

  const { pathWithQueryParams, bypass } = useContext(RedirectContext);

  const { data, loading, error } = useDataModel('layouts', {
    variables: {
      customerType,
      slug
    }
  });

  useHelmet('landingPageRedirector', { data, error, props: { bypass, pathWithQueryParams } }, landingPageHandler, []);

  return null;
};

LandingPageRedirector.displayName = 'LandingPageRedirector';

LandingPageRedirector.propTypes = {
  customerType: string.isRequired,
  slug: string.isRequired
};

LandingPageRedirector.defaultProps = {};

LandingPageRedirector.dataModel = {
  layouts: params({
    customerType: stringType(),
    slug: stringType().isRequired()
  }).shape({
    seo: shape({
      canonicalURL: stringType(),
    }),
  })
};