import React from 'react';
import { node, string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

// Specialized container section for ContentAccordion.
const ContentAccordionSection = ({
  title,
  children,
  anchorId
}) => {

  return (
    <section id={anchorId} className="sui-flex sui-flex-col sui-mt-2 sui-gap-1">
      {title && (
        <div className="sui-pb-4">
          <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>
            {title}
          </Typography>
        </div>
      )}
      {children}
    </section>
  );
};

ContentAccordionSection.propTypes = {
  title: string,
  anchorId: string,
  children: node.isRequired
};

ContentAccordionSection.defaultProps = {
  title: '',
  anchorId: ''
};

export { ContentAccordionSection };