import React, { useEffect } from 'react';
import { string, bool, shape as shapeType } from 'prop-types';
import { StoryHeroRenderer } from './StoryHeroRenderer';
import { StoryHero } from './StoryHero';

const StoryHeroPreview = ({
  componentId,
  componentClass,
  hasSidenav,
  livePreviewData
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('contentfulhero.ready');
  }, []);

  return (
    livePreviewData
      ? (
        <StoryHeroRenderer
          dataComponent={livePreviewData}
          componentId={componentId}
          componentClass={componentClass}
          hasSidenav={hasSidenav}
        />
      )
      : (
        <StoryHero
          componentId={componentId}
          componentClass={componentClass}
        />
      )
  );
};

StoryHeroPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentId: string.isRequired,
  componentClass: string.isRequired,
  hasSidenav: bool,
  livePreviewData: shapeType({})
};

StoryHeroPreview.defaultProps = {
  hasSidenav: false,
  livePreviewData: null
};

StoryHeroPreview.displayName = 'Contentful Story Hero';

export { StoryHeroPreview };
