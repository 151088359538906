import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types';
import { RenderNodes } from './RenderNodes';
import { RenderQuote } from './RenderQuote';
import { RenderTable } from './RenderTable';

export const options = {
  renderNode: {
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="sui-pl-4 sui-list-disc">{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol className="sui-pl-4 sui-list-decimal">{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li>
        <div>{children}</div>
      </li>
    ),
    [BLOCKS.PARAGRAPH]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.HEADING_1]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.HEADING_2]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.HEADING_3]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.HEADING_4]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.HEADING_5]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.HEADING_6]: (node) => (
      <RenderNodes node={node} />
    ),
    [BLOCKS.QUOTE]: (node) => (
      <RenderQuote node={node} />
    ),
    [BLOCKS.TABLE]: (node, children) => (
      <RenderTable node={node} />
    ),
    [INLINES.HYPERLINK]: (node) => (
      <RenderNodes node={node} />
    )
  }
};

export const RichText = ({ content, isLivePreview }) => {
  if (content) {
    let descriptionContent;
    try {
      descriptionContent = isLivePreview ? content : JSON.parse(content);
    } catch (error) {
      return null;
    }
    return documentToReactComponents(descriptionContent, options);
  }
  return null;
};

RichText.propTypes = {
  content: PropTypes.string.isRequired,
  isLivePreview: PropTypes.bool,
};

RichText.defaultProps = {
  isLivePreview: false,
};
