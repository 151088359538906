import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@one-thd/sui-atomic-components';
import { generateNestedKey, getHeaders, getRows } from './utils';
import { RenderNodes } from './RenderNodes';

const RenderTable = ({ node }) => {
  const headers = getHeaders(node);
  const rows = getRows(node);

  return (
    <Table density="loose">
      <TableHead>
        <TableRow>
          {headers.length > 0 && headers.map((header, i) => (
            <TableCell key={generateNestedKey(header, i, 'table-header')}>
              <RenderNodes node={header} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length > 0 && rows.map((row, i) => (
          <TableRow key={generateNestedKey(row, i, 'table-row')}>
            {row.map((cell, jIndex) => {
              if (!cell.nodeType) {
                return (
                  <TableCell key={generateNestedKey(cell, `${jIndex}-${i}`, 'table-cell-nodes')}>
                    {cell.map((nodeText) => {
                      return (
                        <RenderNodes
                          node={nodeText}
                          key={generateNestedKey(nodeText, `${jIndex}-${i}`, 'table-node')}
                        />
                      );
                    })}
                  </TableCell>
                );
              }
              return (
                <TableCell key={generateNestedKey(cell, `${jIndex}-${i}`, 'table-cell')}>
                  <RenderNodes node={cell} />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

RenderTable.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export { RenderTable };
