import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './callout_card.scss';
import { CalloutCardRenderer } from './CalloutCardRenderer';
import { CalloutCard } from './CalloutCard';

const CalloutCardPreview = ({ componentId, componentClass, size, livePreviewData }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('anchor-links.ready');
  }, []);

  let count = 0;
  const capabilityCardContainerCollectionItems = livePreviewData
    ?.capabilityCardContainerCollection
    ?.items.map((item) => {
      const newItem = JSON.parse(JSON.stringify(item));
      newItem.id = newItem.id || count.toString();
      count += 1;
      return newItem;
    });

  return (
    livePreviewData
      ? (
        <CalloutCardRenderer
          componentId={componentId}
          componentClass={componentClass}
          size={size}
          dataComponent={livePreviewData}
          capabilityCardContainer={capabilityCardContainerCollectionItems}
          displayName={CalloutCardPreview.displayName}
        />
      ) : (
        <CalloutCard
          componentId={componentId}
          componentClass={componentClass}
          size={size}
        />
      )

  );
};

CalloutCardPreview.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired,
  size: PropTypes.string,
  livePreviewData: PropTypes.shape({
    sys: PropTypes.shape({
      id: PropTypes.string
    }),
    previewImage: PropTypes.shape({
      damContentSelector: PropTypes.shape({
        assetData: PropTypes.arrayOf(
          PropTypes.shape({
            selectedImageUrl: PropTypes.string
          })
        )
      }),
      damDownloadedContent: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    title: PropTypes.string,
    altText: PropTypes.string,
    description: PropTypes.string,
    capabilityCardContainerCollection: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        headline: PropTypes.string,
        richTextContent: PropTypes.shape({
          json: PropTypes.shape({})
        }),
      }))
    })
  })
};

CalloutCardPreview.defaultProps = {
  size: null,
  livePreviewData: null,
};

CalloutCardPreview.displayName = 'CalloutCardPreview';

export { CalloutCardPreview };
