import React from 'react';
import {
  string, shape, arrayOf
} from 'prop-types';
import { ContentGalleryCardRenderer } from './ContentGalleryCardRenderer';
import { ContentGalleryCard } from './ContentGalleryCard';

const ContentGalleryCardPreview = ({
  componentId,
  componentClass,
  livePreviewData
}) => {

  return (
    livePreviewData
      ? (
        <ContentGalleryCardRenderer
          componentId={componentId}
          componentClass={componentClass}
          dataComponent={livePreviewData}
        />
      )
      : (
        <ContentGalleryCard
          componentId={componentId}
          componentClass={componentClass}
        />
      )
  );
};

ContentGalleryCardPreview.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  livePreviewData: shape({
    id: string,
    componentName: string,
    headline: string,
    link: string,
    altText: string,
    previewImage: shape({
      damContentSelector: shape({
        assetData: arrayOf(shape({
          selectedImageUrl: string
        })
        )
      }),
      damDownloadedContent: shape({
        url: string
      })
    })
  })
};

ContentGalleryCardPreview.defaultProps = {
  livePreviewData: null
};

ContentGalleryCardPreview.displayName = 'ContentGalleryCardPreview';

export { ContentGalleryCardPreview };
