import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge } from '../badge/Badge';
import { Typography } from '../typography/Typography';

const defaultVariantMap = {
  h3: {
    variant: 'h3',
    lineClamp: '3'
  },
  h5: {
    variant: 'h5',
    lineClamp: '3'
  }
};

/**
 * Contains the header and subheader of a `Card` component, along with optional eyebrow and action components.
 *
 * `header` and `subheader` are by default wrapped by a Typography component for standardized typography and for
 * ease of use, allowing you to pass plain string text as prop values. There are mutliple ways to override this
 * behavior.
 *
 * - Use the `headerVariant` prop to swap the default heading typography from h3 to h5 standardized typography.
 *  This will only apply to the header, not the subheader.
 * - Pass an alternative `Typography` component variant as prop values to header and/or subheader.
 * - Set `disableTypography` as `true` to wrap the header text, and optional subheader text with your own custom
 * typography.
 *
 * Related components: [Card](#card), [Typography](#typography)
 *
 * Usage:
 *
 * ```jsx
 * import { CardTitle } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardTitle = React.forwardRef((props, ref) => {

  const {
    action: actionProp,
    actionPosition = 'start',
    component: CardTitleRoot = 'div',
    disableActionSpacing = false,
    padding = false,
    grow = false,
    disableTypography = false,
    eyebrow: eyebrowProp,
    eyebrowOrnament,
    header: headerProp,
    headerVariant = 'h3',
    headerWeight = 'display',
    leadingIcon: leadingIconProp,
    subheader: subheaderProp,
    ...other
  } = props;

  let eyebrow = eyebrowProp;

  if (typeof eyebrowProp === 'string') {
    eyebrow = (
      <Badge startIcon={eyebrowOrnament} variant="strong" color="medium">
        { eyebrow }
      </Badge>
    );
  }

  if (eyebrowProp?.type === Badge && eyebrowOrnament) {
    eyebrow = React.cloneElement(eyebrowProp, { startIcon: eyebrowOrnament });
  }

  let leadingIcon = leadingIconProp && (
    <span className="sui-flex sui-mr-3">
      {leadingIconProp}
    </span>
  );

  let header = headerProp;
  if (header != null && header.type !== Typography && !disableTypography) {
    const headerTypographyProps = defaultVariantMap[headerVariant];
    header = (
      <Typography {...headerTypographyProps} weight={headerWeight}>{header}</Typography>
    );
  }

  let subheader = subheaderProp;
  if (subheader != null && subheader.type !== Typography && !disableTypography) {
    subheader = (
      <div className="sui-mt-1">
        <Typography color="subtle">{subheader}</Typography>
      </div>
    );
  }

  let action = actionProp;
  if (action != null) {
    const actionClasses = classNames('sui-grow-0 sui-shrink-0 sui-basis-auto', {
      'sui-self-start': actionPosition === 'start',
      'sui-self-center': actionPosition === 'center',
      'sui-self-end': actionPosition === 'end',
      'sui-ml-4': !disableActionSpacing,
      'sui-mb-1px': eyebrow
    });
    action = (
      <div className={actionClasses}>
        {action}
      </div>
    );
  }

  const rootClasses = classNames('sui-flex', {
    'sui-p-4': padding,
    'sui-grow': grow
  });

  const headerContainerClasses = classNames('sui-flex sui-flex-col group-hover/card-group:sui-underline', {
    'sui-pt-[6px]': eyebrow
  });

  return (
    <CardTitleRoot
      className={rootClasses}
      ref={ref}
      {...other}
    >
      <div className="sui-flex-auto">
        {
          eyebrow && (
            <div className="sui-flex sui-my-1">
              {eyebrow}
            </div>
          )
        }
        <div className="sui-flex">
          {leadingIcon}
          <div className={headerContainerClasses}>
            {header}
            {subheader}
          </div>
        </div>
      </div>
      {action}
    </CardTitleRoot>
  );
});

CardTitle.displayName = 'CardTitle';

CardTitle.propTypes = {
  /**
   * The action ornament, button or link of the title
   */
  action: PropTypes.node,
  /**
   * The position of the action.
   * @default 'start'
   */
  actionPosition: PropTypes.PropTypes.oneOf(['start', 'center', 'end']),
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, `subheader` and `title` won't be wrapped by a Typography component.
   * @default false
   */
  disableTypography: PropTypes.bool,
  /**
   * If true, the actions do not have additional margin.
   * @default false
   */
  disableActionSpacing: PropTypes.bool,
  /**
   * The contents of the eyebrow.
   */
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The leading ornament within the eyebrow.
   */
  eyebrowOrnament: PropTypes.node,
  /**
   * The contents of the header.
   */
  header: PropTypes.node,
  /**
   * The icon to display before the header.
   */
  leadingIcon: PropTypes.node,
  /**
   * The typography variant to use in header. Either `h3` or `h5`
   * h3: { height: 'snug', lineClamp: '3' }
   * h5: { height: 'snug', lineClamp: '3' }
   * @default 'h3'
   */
  headerVariant: PropTypes.oneOf(['h3', 'h5']),
  /**
   * The typography weight to use in header. Either `bold` or `display`
   * @default 'display'
   */
  headerWeight: PropTypes.oneOf(['bold', 'display']),
  /**
   * If `true`, the Title will grow to take up available space
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * If `true`, the padding is added.
   * @default false
   */
  padding: PropTypes.bool,
  /**
   * The contents of the subheader.
   */
  subheader: PropTypes.node
};

CardTitle.defaultProps = {};

export { CardTitle };
