import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './content-accordion.scss';
import { extend } from '@thd-nucleus/data-sources';
import { ContentAccordionRenderer } from './ContentAccordionRenderer';
import { ContentAccordion } from './ContentAccordion';

const ContentAccordionPreview = ({ componentId, componentClass, livePreviewData }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-accordion.ready'); }, []);

  return (
    livePreviewData ? (
      <ContentAccordionRenderer
        componentId={componentId}
        componentClass={componentClass}
        descriptionContent={livePreviewData?.description?.json}
        data={livePreviewData}
        isLivePreview
      />
    )
      : (
        <ContentAccordion
          componentId={componentId}
          componentClass={componentClass}
        />
      )
  );
};

ContentAccordionPreview.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired,
  livePreviewData: PropTypes.shape({
    description: PropTypes.shape({
      json: PropTypes.shape({}),
    }),
    title: PropTypes.string,
    subtitle: PropTypes.string,
  })
};

ContentAccordionPreview.defaultProps = {
  livePreviewData: null
};

ContentAccordionPreview.displayName = 'ContentAccordionPreview';

ContentAccordionPreview.dataModel = extend(ContentAccordion);

export { ContentAccordionPreview };
