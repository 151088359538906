import React, { useEffect, useState } from 'react';
import { string, object, shape, arrayOf } from 'prop-types';
import { SponsoredCarousel } from '../SponsoredCarousel';
import { cleanString } from '../../utils/utils';

const BasicPLA = ({
  breadCrumbs, browserId, userId, categorySourceId, customRenderId, pageContext, nValue, schema, sponsoredConfig, plaLocation
}) => {
  const formattedCustomRenderId = cleanString(customRenderId);

  const keyProp = sponsoredConfig
   && Object.keys(sponsoredConfig).find((key) => cleanString(key).includes(formattedCustomRenderId));

  // if there is no slugId, find the appropriate config from customRenderId
  const newSlugId = nValue
    || keyProp?.split(customRenderId)[1]?.slice(1)
    || keyProp?.split(formattedCustomRenderId)[1]?.slice(1) || '';

  // This is needed if there is no slugID passed
  const key = `${customRenderId}-${newSlugId}`;
  const configKey = sponsoredConfig
   && Object.keys(sponsoredConfig)?.find((cKey) => cleanString(cKey) === cleanString(key));
  const configObj = configKey && sponsoredConfig[configKey];

  const [component, setComponent] = useState(null);

  const plaToDisplay = () => {
    if (!configObj) return null;
    const { activeAdType } = configObj;
    let activeConfig = configObj?.adConfigs[activeAdType];
    return (
      <SponsoredCarousel
        {...{
          ...activeConfig
        }}
      />
    );
  };

  const getPLAToDisplay = () => {
    setComponent(plaToDisplay());
  };

  useEffect(() => {
    getPLAToDisplay();
  }, []);

  if (component) {
    return <div id="basic-pla" data-testid="basic-pla">{component}</div>;
  }
  return (
    <div id="basic-pla" data-testid="basic-pla">
      <SponsoredCarousel
        {...{
          plaLocation,
          breadCrumbs,
          browserId,
          userId,
          categorySourceId,
          customRenderId,
          pageContext,
          schema,
          nValue: newSlugId
        }}
      />
    </div>
  );

};

BasicPLA.propTypes = {
  plaLocation: string,
  breadCrumbs: arrayOf(shape({})),
  browserId: string,
  userId: string,
  categorySourceId: string,
  schema: string,
  pageContext: shape({}),
  nValue: string,
  customRenderId: string,
  sponsoredConfig: object
};

BasicPLA.defaultProps = {
  plaLocation: '',
  breadCrumbs: [],
  browserId: '',
  userId: '',
  categorySourceId: null,
  pageContext: {},
  schema: '',
  nValue: '',
  customRenderId: '',
  sponsoredConfig: {}
};

export { BasicPLA };
