/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { string as stringType, shape as shapeType } from 'prop-types';
import { PiccolaComposableBannerRenderer } from './PiccolaComposableBannerRenderer';
import { PiccolaComposableBanner } from './PiccolaComposableBanner';

const PiccolaComposableBannerPreview = ({ componentId, componentClass, livePreviewData }) => {
  return (
    livePreviewData ? (
      <PiccolaComposableBannerRenderer
        data={livePreviewData}
        componentId={componentId}
        componentClass={componentClass}
      />
    ) : (
      <PiccolaComposableBanner
        componentId={componentId}
        componentClass={componentClass}
      />
    )
  );
};

PiccolaComposableBannerPreview.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  livePreviewData: shapeType({})
};

PiccolaComposableBannerPreview.defaultProps = {
  componentClass: '',
  livePreviewData: null
};

PiccolaComposableBannerPreview.displayName = 'PiccolaComposableBannerPreview';

export { PiccolaComposableBannerPreview };
