import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import {
  params,
  shape,
  namedFragment,
  customType,
  string as stringType,
  bool as boolType,
  useDataModel,
  typename,
  extend,
  alias
} from '@thd-nucleus/data-sources';
import { RevjetBundleReact } from '@thd-olt-component-react/revjet_bundle';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { StoryHero } from '../StoryHero';
import { HeroPersonalizedWrapper } from './HeroPersonalizedWrapper';

const HeroPersonalized = ({
  componentId, componentClass, thdPageId, searchParams, isSeoBot, backgroundColor, customerType, componentPosition
}) => {

  const { data } = useDataModel('heroPersonalized', {
    variables: {
      id: componentId,
      componentClass,
    }
  });

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const queryParams = new URLSearchParams(searchParams);
  const recommendationValue = queryParams.get('__recommendation_id');
  const revJetContent = data?.component?.personalizedContent || {};
  const contentfulContent = data?.component?.seoHero || {};

  // TODO: add a param to this thunk or make it an IIFE or something
  const getThdPageId = () => {
    const id = thdPageId || '';
    const hasB2C = id.indexOf('-b2c') !== -1;
    const hasB2B = id.indexOf('-b2b') !== -1;

    if (!hasB2C && !hasB2B) {
      return id;
    }

    return id.slice(0, id.length - 4);
  };

  const pageId = getThdPageId();

  const isSSR = typeof window === 'undefined';

  if (
    (isSSR || !mounted || isSeoBot)
        && contentfulContent?.id
  ) {
    return (
      <HeroPersonalizedWrapper
        backgroundColor={backgroundColor}
        isVisible={!isSeoBot}
        usePlaceholder
      >
        <StoryHero
          componentId={contentfulContent?.id}
          componentClass="Hero"
          hasSidenav
        />
      </HeroPersonalizedWrapper>
    );
  }

  if (contentfulContent?.id) {
    return (
      <HeroPersonalizedWrapper backgroundColor={backgroundColor}>
        <ImpressionProvider
          data={{
            id: componentId,
            name: 'HeroPersonalized',
            component: componentClass,
            position: componentPosition,
            type: 'content',
          }}
        >
          <div className="sui-h-[440px] sui-max-h-[440px] hero-personalized">
            <RevjetBundleReact
              tag={revJetContent?.tag || ''}
              plcId={revJetContent?.plcId || ''}
              keyId={revJetContent?.keyId || ''}
              omsid={revJetContent?.omsid || ''}
              deliveryWrapper={revJetContent?.optsDeliveryWrapper || ''}
              autohide={revJetContent?.optsAutohide}
              responsive={revJetContent?.optsResponsive}
              responsiveHeight={revJetContent?.optsResponsiveHeight || ''}
              autoscale={revJetContent?.optsAutoscale}
              deliveryMethod={revJetContent?.optsDeliveryMethod || ''}
              impBannerSize={revJetContent?.optsImpBannerSize || ''}
              deliveryFormat={revJetContent?.optsDeliveryFormat || ''}
              recommendationId={recommendationValue}
              thdPageId={pageId}
              innervateCarouselIndex={revJetContent?.innervateCarouselIndex}
              customerType={customerType}
              carouselPosition={componentPosition}
              shouldTriggerImpression
            />
          </div>
        </ImpressionProvider>
      </HeroPersonalizedWrapper>
    );
  }

  return (
    <HeroPersonalizedWrapper backgroundColor={backgroundColor}>
      <div className="sui-h-[440px] sui-max-h-[440px] hero-personalized">
        <RevjetBundleReact
          tag={revJetContent?.tag || ''}
          plcId={revJetContent?.plcId || ''}
          keyId={revJetContent?.keyId || ''}
          omsid={revJetContent?.omsid || ''}
          deliveryWrapper={revJetContent?.optsDeliveryWrapper || ''}
          autohide={revJetContent?.optsAutohide}
          responsive={revJetContent?.optsResponsive}
          responsiveHeight={revJetContent?.optsResponsiveHeight || ''}
          autoscale={revJetContent?.optsAutoscale}
          deliveryMethod={revJetContent?.optsDeliveryMethod || ''}
          impBannerSize={revJetContent?.optsImpBannerSize || ''}
          deliveryFormat={revJetContent?.optsDeliveryFormat || ''}
          recommendationId={recommendationValue}
          thdPageId={pageId}
          innervateCarouselIndex={revJetContent?.innervateCarouselIndex}
          customerType={customerType}
          carouselPosition={componentPosition}
        />
      </div>
    </HeroPersonalizedWrapper>
  );
};

HeroPersonalized.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  searchParams: string,
  thdPageId: string,
  isSeoBot: bool,
  backgroundColor: string,
  componentPosition: string,
  customerType: string
};

HeroPersonalized.defaultProps = {
  searchParams: '',
  thdPageId: '',
  isSeoBot: false,
  backgroundColor: 'white',
  componentPosition: '',
  customerType: 'b2c'
};

HeroPersonalized.displayName = 'HeroPersonalized';

RevjetBundleReact.dataModel = {
  heroPersonalized: alias('component').params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['RevJetModule']).isRequired(),
  }).shape({
    RevJetModule: namedFragment({ inline: true, fragmentType: 'RevJetModule' }).shape({
      id: stringType(),
      tag: stringType().isRequired(),
      keyId: stringType().isRequired(),
      plcId: stringType(),
      omsid: stringType(),
      optsDeliveryWrapper: stringType(),
      optsResponsive: boolType(),
      optsResponsiveHeight: stringType(),
      optsImpBannerSize: stringType(),
      optsDeliveryMethod: stringType(),
      optsDeliveryFormat: stringType(),
      optsAutohide: boolType(),
      optsAutoscale: boolType(),
      innervateCarouselIndex: stringType(),
      __typename: typename('RevJetModule')
    })
  })
};

HeroPersonalized.dataModel = extend(
  {
    heroPersonalized: alias('component').params({
      id: stringType().isRequired(),
      componentClass: customType('ComponentClass').enum(['HeroPersonalized']).isRequired(),
    }).shape({
      HeroPersonalized: namedFragment({ inline: true, fragmentType: 'HeroPersonalized' }).shape({
        id: stringType(),
        name: stringType(),
        personalizedContent: shape({
          ...RevjetBundleReact.dataModel.heroPersonalized
        }),
        seoHero: shape({
          ...StoryHero.dataModel.component,
        }),
        __typename: typename('HeroPersonalized')
      })
    }),
  },
  StoryHero
);

export { HeroPersonalized };