import React from 'react';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';

import { chunkArray } from '../../utils';
import '../callout_card.scss';

const mdSlides = (slides) => slides?.map((group, groupKey) => {
  const rowsPerSlide = chunkArray(group, 2);

  return (
    <div className="sui-flex sui-gap-y-6 sui-flex-col" key={groupKey}>
      {rowsPerSlide?.map((row, rowKey) => {
        return (
          <div className="sui-grid sui-grid-cols-2 sui-grid-rows-1 sui-gap-x-6" key={rowKey}>
            {row?.map((card, cardKey) => (
              <div className="row_card" key={cardKey + card.id}>
                <CapabilityCard
                  headline={card.headline}
                  richText={typeof card.richTextContent === 'string'
                    ? card.richTextContent
                    : JSON.stringify(card.richTextContent?.json)}
                  componentPosition={cardKey + 1}
                  componentId={card.id || card.sys?.id}
                />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
});

export default mdSlides;
