export const LayoutTypes = {
  Educational: 'pageEducationalLayout',
  Navigational: 'navigationalLayout',
  Inspirational: 'inspirationalLayout',
  Flexible: 'flexibleLayout',
};

export const getLayoutClass = (layoutType) => new Map([
  [LayoutTypes.Educational, 'EducationalLayout'],
  [LayoutTypes.Navigational, 'NavigationalLayout'],
  [LayoutTypes.Inspirational, 'InspirationalLayout'],
  [LayoutTypes.Flexible, 'UniversalLayout'],
]).get(layoutType) || 'EducationalLayout';

const isRichTextField = (str) => {
  try {
    return JSON.parse(str)?.nodeType?.toString() === 'document';
  } catch (error) {
    return false;
  }
};

// formats CIA data to match what useContentfulLiveUpdates expects
export const formatCiaData = (inputData) => {
  if (inputData) {
    const data = inputData.component || inputData.container || inputData;
    let results = JSON.parse(JSON.stringify(data));
    if (data instanceof Array) {
      let newArray = [data.length];
      for (let i = 0; i < data.length; i += 1) {
        newArray[i] = formatCiaData(data[i]);
      }
      return newArray;
    }
    if (typeof data !== 'string') {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (key === 'id') {
          results.sys = { id: data[key] };
        } else if (isRichTextField(data[key])) {
          results[key] = { json: JSON.parse(data[key]) };
        } else if (data[key] instanceof Array) {
          const newKey = key + 'Collection';
          const newArray = formatCiaData(data[key]);
          results[newKey] = { items: [...newArray] };
        } else if (typeof data[key] === 'object' && data[key] !== {}) {
          results[key] = formatCiaData(data[key]);
        }
      }
    }
    return results;
  }
  return null;
};

// checks if data has any significant data
export const isEmpty = (data) => {
  if (data) {
    if (typeof data === 'string') {
      return false;
    }
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (key !== '__typename' && key !== 'sys' && data[key]) {
        if (typeof data[key] === 'string' || data[key] === {}) {
          return false;
        }
        return isEmpty(data[key]) !== false;
      }
    }
    return true;
  }
  return true;
};

// formats data from useContentfulLiveUpdates to match what the nucleus components expect (a.k.a. CIA format)
export const formatContentfulData = (data) => {
  if (data && typeof data !== 'string' && data !== {}) {
    let results = JSON.parse(JSON.stringify(data));
    Object.keys(results).forEach((key) => {
      if (results[key]?.sys?.id && !results[key]?.id) {
        results[key].id = results[key].sys.id;
      }

      if (results[key]?.__typename === 'GenericSection') {
        results[key].__typename = 'Section';
      }

      if (results[key] instanceof Object) {
        if (key.includes('Collection')) {
          const ciaKey = key.replace('Collection', '');
          if (results[key]?.items.length > 0 && results[key].items[0] !== 0) {
            results[ciaKey] = formatContentfulData(results[key].items);
          }
        }
        results[key] = formatContentfulData(results[key]);
      }
    });

    return results;
  }
  return data;
};

// gets the livePreview data for a specific component (by id) from the provided livePreviewData
export const getComponentData = (livePreviewData, id) => {
  const contentData = livePreviewData?.layouts?.content;

  const checkIfIdMatches = (content) => {
    if (!content) {
      return false;
    } if (content?.sys?.id && content.sys.id === id) {
      return content;
    }

    try {
      return Object.keys(content).reduce((component, key) => {
        if (component) return component;
        if (content[key] && typeof content[key] !== 'string') {
          return checkIfIdMatches(content[key]);
        }
        return component;
      }, undefined);
    } catch (error) {
      console.error(error);
    }

    return false;
  };

  return checkIfIdMatches(contentData);
};
