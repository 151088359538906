import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { getVariant, getMarks, generateKey, getDetailedVariant } from './utils';
import { RenderHyperlink } from './RenderHyperlink';

const RenderNodes = ({ node }) => {
  if (!node || node?.content.length === 0) {
    return null;
  }
  const content = node?.content;
  const mainVariant = getVariant(node?.nodeType);
  return (
    <div className="sui-flex sui-flex-wrap">
      {content.map((item, i) => {
        if (item.nodeType === 'hyperlink') {
          // contentful requires uri to nodeType hyperlink
          const uriString = item.data.uri.length;
          return (
            <RenderHyperlink
              key={`${i}-${mainVariant}-hyperlink-${item.data.uri.substring(uriString - 7)}`}
              item={item}
              variant={mainVariant}
              linkIndex={i}
            />
          );
        }
        if (!item.nodeType.includes('embedded')) {
          const isBigText = mainVariant === 'h1' || mainVariant === 'h2' || mainVariant === 'h3';
          const subStyle = isBigText ? 'sui-mt-4' : 'sui-mt-2';
          const value = getMarks(item.marks).isItalic ? (<i>{item.value}</i>) : item.value;
          return (
            <div
              className={value !== '' && 'sui-mr-1'}
              style={getMarks(item.marks).isCode ? { backgroundColor: '#E1E2E0' } : null}
            >
              <Typography
                paragraph
                key={generateKey(item.value, i, mainVariant)}
                component="div"
                variant={getDetailedVariant(item, isBigText, mainVariant)}
                weight={getMarks(item.marks).isBold ? 'bold' : 'regular'}
                {...(getMarks(item.marks).isUnderline ? { decoration: 'underline' } : {})} // eslint-disable-line
              >
                {getMarks(item.marks).isSub ? <div className={subStyle}>{value}</div> : value}
                {value === '' && <div>&nbsp;</div>}
              </Typography>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

RenderNodes.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
    nodeType: PropTypes.string,
  }).isRequired
};

export { RenderNodes };
