export const DIMENSIONS = {
  GAM: {
    MOVE: {
      HEIGHT: {
        MOBILE: 230,
        DESKTOP: 181
      },
      WIDTH: {
        MOBILE: 394,
        DESKTOP: 1180
      }
    },
    DIY: {
      HEIGHT: {
        MOBILE: 230,
        DESKTOP: 175
      },
      WIDTH: {
        MOBILE: 450,
        DESKTOP: 1420
      }
    },
    DEFAULT: {
      HEIGHT: {
        MOBILE: 170,
        DESKTOP: 148
      },
      WIDTH: {
        MOBILE: 450,
        DESKTOP: 1420
      }
    }
  }
};

export const MARGINTOP = {
  GAM: {
    MOVE: 48,
    DEFAULT: 0
  }
};
