import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@one-thd/sui-atomic-components';
import { RenderNodes } from './RenderNodes';
import { generateNestedKey } from './utils';

const RenderQuote = ({ node }) => {
  return (
    <div className="sui-flex sui-justify-center">
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <Card className="sui-w-[90%] sui-border-l-4">
        <CardBody>
          {node?.content.map((item, i) => {
            return (
              <RenderNodes
                node={item}
                key={generateNestedKey(node, i, 'quote')}
              />
            );
          })}
        </CardBody>
      </Card>
    </div>
  );
};

RenderQuote.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export { RenderQuote };
