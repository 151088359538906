import React from 'react';
import { string as stringType, shape as shapeType } from 'prop-types';
import { BannerRenderer } from './BannerRenderer';
import { PiccolaPencil } from './PiccolaPencil';

const PiccolaPencilPreview = ({ componentId, componentClass, livePreviewData }) => {

  return (
    livePreviewData
      ? (
        <BannerRenderer
          componentId={componentId}
          componentClass={componentClass}
          data={livePreviewData}
          mobileAspectRatio="sui-aspect-[125/29]"
          tabletAspectRatio="md:sui-aspect-[1160/60]"
          desktopAspectRatio="xl:sui-aspect-[1160/60]"
        />
      )
      : (
        <PiccolaPencil
          componentId={componentId}
          componentClass={componentClass}
        />
      )
  );
};

PiccolaPencilPreview.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  livePreviewData: shapeType({})
};

PiccolaPencilPreview.defaultProps = {
  componentClass: '',
  livePreviewData: null
};

PiccolaPencilPreview.displayName = 'PiccolaPencilPreview';

export { PiccolaPencilPreview };
