/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import PropTypes from 'prop-types';
import ThemeContext from './ThemeContext';
import { useTheme } from './useTheme';

const ThemeInjector = (props) => {
  const [parentElement, setParentElement] = React.useState(null);
  const Wrapper = parentElement ? React.Fragment : 'div';
  const ref = React.useRef(null);
  const targetIndex = React.useRef(0);

  const { palette = {} } = useTheme();

  React.useEffect(() => {
    if (ref.current?.parentElement) {
      targetIndex.current = Array.from(ref.current.parentElement.children).indexOf(ref.current);
      setParentElement(ref.current.parentElement);
    }
  }, []);

  React.useEffect(() => {
    if (parentElement) {
      const {
        mode = 'light',
        modeAttribute,
        ...restPalette
      } = palette;
      parentElement?.children[targetIndex.current].setAttribute('data-sui-palette', mode);
      for (const [key, value] of Object.entries(restPalette)) {
        parentElement?.children[targetIndex.current].style.setProperty(key, value);
      }
    }
  }, [parentElement, targetIndex, palette]);

  return (
    <Wrapper ref={parentElement ? undefined : ref}>
      {props.children}
    </Wrapper>
  );
};

const ThemeInjectorSsr = (props) => {

  const { palette = {} } = useTheme();

  const {
    mode = 'light',
    modeAttribute,
    ...restPalette
  } = palette;

  return (
    <div {...modeAttribute} style={{ ...restPalette }}>
      {props.children}
    </div>
  );
};

/**
 * Use the `ThemeProvider` component in order to inject a theme into your application.
 * However, this is optional; SUI components come with a default light theme.
 *
 * `ThemeProvider` relies on React context to pass the theme down to the components,
 * so make sure `ThemeProvider` is a parentof the components you want to customize.
 *
 * Current Theme configuration variables: `palette`.
 *
 * Future Mode configuration variables: `typography`, `spacing`, `effects`, etc.
 *
 * Usage:
 *
 * ```jsx
 * import { ThemeProvider } from '@one-thd/sui-atomic-components';
 * ```
 */
const ThemeProvider = (props) => {

  const { children, ssr = false, theme } = props;

  const Injector = ssr ? ThemeInjectorSsr : ThemeInjector;

  return (
    <ThemeContext.Provider value={theme}>
      <Injector>
        {children}
      </Injector>
    </ThemeContext.Provider>
  );
};

ThemeProvider.displayName = 'ThemeProvider';

ThemeProvider.propTypes = {
  /**
   * Your component tree.
   */
  children: PropTypes.node,
  /**
   * A theme object.
   */
  theme: PropTypes.object.isRequired,
  /**
   * If `true`, will add theming attributes to a root div.
   */
  ssr: PropTypes.bool,
};

export { ThemeProvider };