import { HTTP_CODE } from '../../utils/redirector-utils';

export const isNotFound = ({ data }) => {
  if (data && !data?.searchModel?.metadata) {
    return {
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: 'Page not found'
    };
  }
  return null;
};

export const isLandingPageSearchCanonical = ({ data, path }) => {
  let { canonicalUrl } = data?.searchModel?.metadata || {};
  if (canonicalUrl && path !== canonicalUrl) {
    return {
      shouldRedirect: true,
      httpCode: HTTP_CODE.PERM_REDIRECT,
      redirectPath: canonicalUrl,
      description: 'redirected for canonical'
    };
  }
  return null;
};