import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf,
  namedFragment,
  typename,
  customType,
  params,
  shape,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import './callout_card.scss';
import { CalloutCardRenderer } from './CalloutCardRenderer';

const CalloutCard = ({ componentId, componentClass, size }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('callout-card.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  return (
    <CalloutCardRenderer
      componentId={componentId}
      componentClass={componentClass}
      size={size}
      dataComponent={data?.component}
      capabilityCardContainer={data?.component.capabilityCardContainer}
      displayName={CalloutCard.displayName}
    />
  );
};

CalloutCard.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired,
  size: PropTypes.string,
};

CalloutCard.defaultProps = {
  size: null,
};

CalloutCard.displayName = 'CalloutCard';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

CalloutCard.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['CalloutCard']).isRequired()
  }).shape({
    CalloutCard: namedFragment({
      inline: true,
      fragmentType: 'CalloutCard'
    }).shape({
      id: stringType(),
      altText: stringType(),
      previewImage: DamMediaFragment,
      title: stringType(),
      description: stringType(),
      capabilityCardContainer: arrayOf(shape({
        id: stringType(),
        headline: stringType(),
        richTextContent: stringType(),
        previewImage: DamMediaFragment,
      }))
    })
  })
};

export { CalloutCard };
