import React, { useEffect, useMemo } from 'react';

import {
  arrayOf,
  customType,
  alias,
  fragment,
  namedFragment,
  number as numberType,
  params,
  shape,
  typename,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { string, bool, number } from 'prop-types';
import './brand-hero.scss';
import { BrandHeroRenderer } from './BrandHeroRenderer';

const BrandHero = ({ componentId, componentClass, withSideNav, componentPosition }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hero.ready');
  }, []);

  const { data } = useDataModel('brandedComponent', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  return (
    <BrandHeroRenderer
      componentId={componentId}
      componentClass={componentClass}
      withSideNav={withSideNav}
      componentPosition={componentPosition}
      dataComponent={data?.component}
    />
  );
};

BrandHero.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  withSideNav: bool,
};

BrandHero.defaultProps = {
  withSideNav: undefined,
  componentPosition: 1,
};

BrandHero.displayName = 'BrandHero';

BrandHero.dataModel = {
  brandedComponent: alias('component').params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Hero']).isRequired(),
  }).shape({
    Hero: namedFragment({
      inline: true,
      fragmentType: 'Hero',
      fragmentAlias: 'BrandHeroFields'
    }).shape({
      id: stringType(),
      eyebrow: stringType(),
      title: stringType(),
      description: stringType(),
      cta: stringType(),
      link: stringType(),
      altText: stringType(),
      altTextForLogoBadge: stringType(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
      backgroundColor: stringType(),
      // textColor is a required field
      textColor: stringType(),
      // buttonColor is a required field
      buttonColor: stringType(),
      logoOrBadge: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
    }),
  }),
};

export { BrandHero };
