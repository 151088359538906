import React from 'react';
import { ContentSection } from '@thd-olt-component-react/content-section';
import { EmtDescription } from '@thd-olt-component-react/emt-generated-content';
import { LinkTag, ScriptTag } from '@thd-nucleus/thd-helmet';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { NoIndexMetadata, NoIndexNoFollowMetadata } from '@thd-olt-component-react/metadata';
import { ContentAccordionSection } from '../specialtyContainers/ContentAccordionSection';
import { getComponentData } from '../../pages/utils/previewUtils';

// TODO: [AME-2113] This is a temporary fix-forward but will need to remove this in near future when stable.
export const seoTagPreloader = ({ data }) => {
  const seo = data?.layouts?.seo;
  const tags = [];

  if (!seo) return tags;

  const linkData = seo.linkData;
  const scriptData = seo.scriptData;
  /* Waiting for CIA changes in order to move this logic to the Metadata component. Implementation pending */
  if (linkData) {
    const linkAttrs = linkData.split(' ');
    const hrefAttrs = linkAttrs.find((attr) => attr.startsWith('href'));
    const href = hrefAttrs ? hrefAttrs.split('=')[1] : '';

    const linkTag = new LinkTag({
      id: 'alternate', 'data-th': 'server', rel: 'alternate', media: 'only screen and (max-width: 640px)', href: href.substring(1, href.length - 1)
    });
    tags.push(linkTag);
  }

  if (scriptData) {
    const splitScript = scriptData.split('>')[1].split('<')[0];
    const scriptTag = new ScriptTag({ 'data-th': 'server', type: 'application/ld+json', id: 'breadcrumbStructuredData', content: splitScript });
    tags.push(scriptTag);
  }

  return tags;
};

// Appends Image Manager query params to request a more appropriate sized hero image size.
const akamaiImageResize = (imageUrlToTransform) => {
  // Specific to Hero image size.
  const queryParams = 'Resize=(920,575)';

  if (!imageUrlToTransform) {
    return imageUrlToTransform;
  }
  const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
  if (!isAkamaiHost) {
    return imageUrlToTransform;
  }
  if (!imageUrlToTransform.includes('?')) {
    return `${imageUrlToTransform}?im=${queryParams}`;
  }
  if (imageUrlToTransform.includes('im=')) {
    const urlBeforeImParams = imageUrlToTransform.substring(0, imageUrlToTransform.indexOf('im='));
    const imAndRemainderParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
    const hasAdditionalParams = imAndRemainderParams.includes('&');
    if (hasAdditionalParams) {
      const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
      const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
      return `${urlBeforeImParams}${imParams};${queryParams}${remainderOfParams}`;
    }
    return `${imageUrlToTransform};${queryParams}`;
  }
  return `${imageUrlToTransform}&im=${queryParams}`;
};

// To help lower LCP, if the heroCarousel is configured in Contentful, try to determine which image will be rendered
// to the top of the page. Once determine, add that URL to the head of the page so it'll load as soon as the page
// loads.
export const imagePreloader = ({ data: contentData }) => {
  const heroCarousel = contentData?.layouts?.content?.heroCarousel;
  if (!heroCarousel) {
    return [];
  }

  const firstComponent = heroCarousel.components[0];
  const componentType = firstComponent.__typename;
  let componentWithImage = firstComponent;
  if (componentType === 'ComponentSelector') {
    let { defaultComponent } = firstComponent;
    if (defaultComponent.__typename === 'Slot') {
      componentWithImage = defaultComponent.content;
    } else {
      componentWithImage = defaultComponent;
    }
  }

  const imageUrlToPreload = (componentWithImage?.previewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl
    || componentWithImage?.previewImage?.damDownloadedContent?.url
    || componentWithImage?.image?.url);

  if (!imageUrlToPreload) {
    return [];
  }

  const isImageAGif = imageUrlToPreload.split('?')[0].endsWith('.gif');
  const imageUrlToPreloadWithParams = isImageAGif ? imageUrlToPreload : akamaiImageResize(imageUrlToPreload);

  const tags = [
    new LinkTag({
      rel: 'preload', fetchPriority: 'high', href: imageUrlToPreloadWithParams, id: 'preloadFirstImg', as: 'image'
    })
  ];

  return tags;
};

export const parseContent = ({ componentMapper, data, customProps = {}, layout }) => {
  if (!data?.layouts?.content) {
    return {};
  }

  const { content } = data.layouts;

  const Content = {};
  const promoVisNavTitles = {};

  function renderComponent(component, position, componentProps = {}) {
    const typename = component.__typename;

    const { componentName } = componentProps;

    // The below logic was added because there are two title values: one on the Section level, and the other on the Component level.
    // As the renderComponent is recursive, we ensure that we save the right Section level title value into promoVisNavTitles and later reassign the value to the Component level title.
    if (componentName === 'promoVisualNavigation1'
    || componentName === 'promoVisualNavigation2'
    || componentName === 'promoVisualNavigation3') {
      const id = component.components[0].id;
      promoVisNavTitles[id] = componentProps.title;
    }

    const livePreviewData = getComponentData(data, component.id);
    const allComponentProps = { ...componentProps, ...(componentName === 'heroCarousel' && { title: null }), livePreviewData };

    switch (typename) {
    case 'Hero':
      allComponentProps.hasSidenav = true;
      break;
    case 'SpecialBuyOfTheDay':
      allComponentProps.isUppercaseHeader = true;
      break;
    case 'VisualNavigation':
      allComponentProps.isInSection = true;
      allComponentProps.experienceHasSideNav = true;
      break;
    case 'PromoVisualNavigation':
      allComponentProps.experienceHasSideNav = true;
      break;
    default:
      // leave as is
      break;
    }

    let props = {
      componentId: component.id,
      componentClass: typename,
      componentPosition: position,
      key: position,
      ...((typename === 'SpecialBuyOfTheDay' || typename === 'EndCap') && { entryId: component.id }),
      ...(typename === 'PromoVisualNavigation' && { title: promoVisNavTitles[component.id] || componentProps.dynamicPromoVisNavTitle }),
      ...(typename === 'Description_New' && { title: component.title, description: component.descriptionText, useDefaultFontStylesForHeadings: true }),
      ...allComponentProps,
      ...customProps[typename] && customProps[typename](component)
    };

    const newDynamicComponent = React.createElement(
      componentMapper.getComponent(typename),
      props,
      component.components?.map((comp, index) => renderComponent(comp, index + 1))
    );

    const impressableComponents = ['HeroCarousel', 'Section', 'ProductShelf'];

    if (impressableComponents.includes(typename)) {
      const isProductShelf = typename === 'ProductShelf';
      const isHeroCarousel = componentName === 'heroCarousel';

      const impressionData = {
        id: component.id,
        component: isProductShelf ? 'ProductShelf' : 'Section',
        name: 'Section',
        type: isProductShelf ? 'product' : 'content'
      };
      if (isProductShelf) {
        impressionData.name = 'ProductShelf';
      }
      if (isHeroCarousel) {
        impressionData.name = 'HeroCarousel';
      }

      return (
        <ImpressionProvider
          key={position || 1}
          data={impressionData}
        >
          {newDynamicComponent}
        </ImpressionProvider>
      );
    }

    return newDynamicComponent;
  }

  function addDynamicSection(dynamicSection, keyName) {
    Content[keyName] = [];

    dynamicSection.forEach((subSection) => {
      let { isCarousel, title, id } = subSection;
      const componentName = subSection.components[0].__typename;
      const dynamicPromoVisNavTitle = componentName === 'PromoVisualNavigation' ? title : '';

      const subSectionProps = isCarousel ? {
        spaceBetweenXs: 16,
        spaceBetweenSm: 24,
        spaceBetweenMd: 24,
        spaceBetweenLg: 32,
        spaceBetweenXl: 32,
        slidesLg: 3,
        slidesXl: 3,
        slidesMd: 3,
        slidesSm: 2,
        slidesXs: 2,
      } : {};

      // Determine section type to use
      let sectionType;
      switch (componentName) {
      case 'ContentAccordion':
        sectionType = ContentAccordionSection;
        break;
      default:
        sectionType = ContentSection;
        break;
      }

      const childSection = React.createElement(
        sectionType,
        { isCarousel, title, ...subSectionProps },
        subSection.components?.map((comp, index) => renderComponent(comp, index + 1, { dynamicPromoVisNavTitle }))
      );
      Content[keyName].push(() => React.createElement(
        ImpressionProvider,
        { data: { id,
          component: 'Section',
          type: 'content',
          name: 'Section' } },
        childSection
      ));
    });
  }

  function addDescriptionNew(richText, keyName) {
    Content[keyName] = [];

    richText.forEach((descriptionNew) => {
      let { title, descriptionText } = descriptionNew;

      Content[keyName].push(() => React.createElement(
        EmtDescription,
        { title, description: descriptionText, useDefaultFontStylesForHeadings: true }
      ));
    });
  }

  function addComponent(componentName, component) {
    let { isCarousel, title, ...otherProps } = component;
    let isSection = component.__typename === 'Section' || component.__typename === 'HeroCarousel';
    Content[componentName] = (props) => renderComponent(component, 1, { ...isSection && { isCarousel, title, componentName, ...otherProps }, ...props });
  }

  componentMapper.getMainContentContentfulKeys(layout).map((key) => {
    const isDynamicSection = key.includes('dynamicSections') || key.includes('flexibleCluster');
    const isRichText = key.includes('richText');
    const contentSection = content[key];
    const typename = contentSection?.__typename;
    if (Array.isArray(contentSection)) {
      if (isDynamicSection) {
        addDynamicSection(contentSection, key);
      }
      if (isRichText) {
        addDescriptionNew(contentSection, key);
      }
    } else if (componentMapper.getComponent(typename)) {
      addComponent(key, contentSection);
    } else if (!contentSection) {
      // Even though section content does not exist it still needs to be added to the Content object with a default
      // value set to an empty React Fragment. If we do not do this, the calling code that tries to reference this
      // component will cause an error and prevent the page from loading.

      Content[key] = isDynamicSection ? [] : () => React.Fragment;
    }

    return null;
  });

  return {
    Content
  };
};

export const parseSponsoredContent = ({ componentMapper, data, customProps = {}, layout }) => {
  if (!data?.layouts?.content) {
    return {};
  }

  const { content } = data.layouts;

  const Sponsored = {};

  function renderComponent(component, position, componentProps = {}) {
    const typename = component.__typename;

    if (!componentMapper.getComponent(typename)) {
      return null;
    }

    const allComponentProps = { ...componentProps };

    let props = {
      componentId: component.id,
      componentClass: typename,
      componentPosition: position,
      key: position,
      ...allComponentProps,
      ...customProps[typename] && customProps[typename](component)
    };

    const newDynamicComponent = React.createElement(
      componentMapper.getComponent(typename),
      props,
      component.components?.map((comp, index) => renderComponent(comp, index + 1))
    );
    return newDynamicComponent;
  }

  function addComponent(componentName, component) {
    Sponsored[componentName] = (props) => renderComponent(component, 1, { ...props });
  }

  componentMapper.getSponsoredContentContentfulKeys(layout).map((key) => {
    const contentSection = content[key];
    const typename = contentSection?.__typename;

    if (componentMapper.getComponent(typename)) {
      addComponent(key, contentSection);
    } else if (!contentSection) {
      Sponsored[key] = () => React.Fragment;
    }
    return null;
  });

  return {
    Sponsored: {
      ...Sponsored
    }
  };
};

export const renderRobotTag = (data) => {
  if (data.no_follow && data.no_index) {
    return <NoIndexNoFollowMetadata />;
  }

  if (data.no_index) {
    return <NoIndexMetadata />;
  }

  return null;
};
