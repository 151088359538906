import { CalloutCardPreview } from '@thd-olt-component-react/callout_card';
import { ContentAccordionPreview } from '@thd-olt-component-react/content-accordion';
import { StoryHeroPreview } from '@thd-olt-component-react/hero';
import {
  MedioInlinePreview,
  PiccolaPencilPreview,
  MedioComposableBannerPreview,
  PiccolaComposableBannerPreview
} from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperPreview } from '@thd-olt-component-react/newspaper-module';
import { ResourcePreview } from '@thd-olt-component-react/resource';
import { ContentGalleryCardPreview } from '@thd-olt-component-react/content-gallery-card';
import { SpotlightPreview } from '@thd-olt-component-react/spotlight';
import { VisualNavigationPreview } from '@thd-olt-component-react/visual-navigation';
import { ProductShelfPreview } from '@thd-olt-component-react/product-shelf';
import { componentMapper } from './componentMapper';

const buildComponentMapperObj = () => {
  const previewComponents = {
    CalloutCard: CalloutCardPreview,
    ContentAccordion: ContentAccordionPreview,
    Gallery: ContentGalleryCardPreview,
    Hero: StoryHeroPreview,
    MedioInline: MedioInlinePreview,
    MedioComposableBanner: MedioComposableBannerPreview,
    PiccolaComposableBanner: PiccolaComposableBannerPreview,
    Newspaper: NewspaperPreview,
    PiccolaPencil: PiccolaPencilPreview,
    ProductShelf: ProductShelfPreview,
    Resource: ResourcePreview,
    Spotlight: SpotlightPreview,
    VisualNavigation: VisualNavigationPreview,
  };

  const contentfulTypeToComponentMap = {
    ...componentMapper.getContentfulTypeToComponentMap(),
    ...previewComponents,
  };

  return {
    getComponentMap: () => {
      return contentfulTypeToComponentMap;
    },
    getComponent: (contentfulType) => {
      return contentfulTypeToComponentMap[contentfulType];
    },
    getMainContentContentfulKeys: (layout) => {
      return componentMapper.getLayoutContentfulKeys()[layout];
    },
    getSponsoredContentContentfulKeys: (layout) => {
      return componentMapper.getLayoutSponsoredKeys()[layout];
    }
  };
};

export const previewComponentMapper = buildComponentMapperObj();
