import { useContext, useCallback } from 'react';
import { ImpressionContext } from './ImpressionContext';
import {
  impressionAnalytics,
  impressionFallbackCheck,
  impressionStringRefinement,
  createClickId,
} from '../utils/index';

const useImpressionCallback = (props) => {

  let { data, additionalData, parent } = useContext(ImpressionContext);
  // this ^^^ is taking the object from impression context and
  // using it as a template to bring in values set in the data object
  // of the ImpressionProvider, or useImpression Hook.

  // allows the user to skip using multiple ImpressionProviders by passing in their own props to useImpression hook
  if (props) {
    parent = data;
    data = props.data;
  }

  const clickID = createClickId({ data, parent });

  const impressionCallback = useCallback((callbackProps) => {
    const {
      callbackImpression = {},
      callbackContainer = {}
    } = callbackProps;

    const impression = {
      ...data,
      ...callbackImpression
    };

    const container = {
      ...parent,
      ...callbackContainer
    };

    impressionFallbackCheck(impression, container);

    impressionStringRefinement(impression, container);

    impressionAnalytics({ impressionData: {
      impression,
      clickID,
      container
    } });
  }, [data, parent]);

  return {
    data, additionalData, parent, clickID, impressionCallback
  };
};

export { useImpressionCallback };
