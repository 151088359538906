import { useContext } from 'react';
import { string } from 'prop-types';
import { RedirectTag, useHelmet } from '@thd-nucleus/thd-helmet';
import {
  params,
  shape,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { RedirectContext } from './RedirectContext';
import { isLandingPageSearchCanonical, isNotFound } from './rules/landing-page-search-redirect-rules';
import { handleErrors, setUrlContext } from '../utils/redirector-utils';

const landingPageSearchHandler = ({ data, error, props }) => {

  let [path, queryParams = ''] = props.pathWithQueryParams?.split('?') || '';

  path = setUrlContext(path, '/c/');

  const debug = queryParams.indexOf('debug') > -1;

  const errors = handleErrors(error);
  if (errors) {
    return new RedirectTag(errors, props.bypass);
  }

  const notFound = isNotFound({ data });
  if (notFound) {
    return new RedirectTag(notFound, props.bypass);
  }

  const landingPageSearchCanonical = isLandingPageSearchCanonical({ data, path });

  if (landingPageSearchCanonical && !debug) {
    return new RedirectTag(landingPageSearchCanonical, props.bypass);
  }
  return null;
};

export const LandingPageSearchRedirector = ({ navParam }) => {

  const { pathWithQueryParams, bypass } = useContext(RedirectContext);

  const { data, loading, error } = useDataModel('searchModel', {
    variables: {
      navParam
    }
  });

  // eslint-disable-next-line max-len
  useHelmet('landingPageSearchRedirector', { data, error, props: { bypass, pathWithQueryParams } }, landingPageSearchHandler, []);

  return null;
};

LandingPageSearchRedirector.displayName = 'LandingPageSearchRedirector';

LandingPageSearchRedirector.propTypes = {
  navParam: string.isRequired
};

LandingPageSearchRedirector.defaultProps = {};

LandingPageSearchRedirector.dataModel = {
  searchModel: params({
    navParam: stringType()
  }).shape({
    metadata: shape({
      canonicalUrl: stringType()
    }),
  })
};