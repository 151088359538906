import React from 'react';
import { string, number, bool as boolean, shape as shapeType } from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardMedia,
  CardTitle,
  Typography
} from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';
import { useImpression } from '@thd-olt-component-react/impression';
import VideoPlayerWrap from './VideoPlayerWrap';
import styles from './resource.scss';

const ResourceRenderer = ({
  componentClass,
  componentPosition,
  boldTitle,
  lazyLoad,
  componentId,
  dataComponent,
}) => {
  const cx = classNames.bind(styles);

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: ResourceRenderer.displayName,
      component: ResourceRenderer.displayName,
      position: componentPosition,
      type: 'content',
    }
  });

  let {
    title,
    altText,
    link,
    description,
    thumbNailImage: thumb,
    eyebrow,
    previewImage,
    video
  } = dataComponent;

  const { url } = dataComponent?.image?.damDownloadedContent || {};
  let imageUrl = thumb || url;

  const akamaiImageResize = (imageUrlToTransform) => {
    const queryParams = 'Resize=(703,395.44)';

    if (!imageUrlToTransform) {
      return imageUrlToTransform;
    }
    const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
    if (!isAkamaiHost) {
      return imageUrlToTransform;
    }
    if (!imageUrlToTransform.includes('?')) {
      return `${imageUrlToTransform}?im=${queryParams}`;
    }
    if (imageUrlToTransform.includes('im=')) {
      const urlBeforeImParams = imageUrlToTransform.substring(0, imageUrlToTransform.indexOf('im='));
      const imAndRemainderParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
      const hasAdditionalParams = imAndRemainderParams.includes('&');
      if (hasAdditionalParams) {
        const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
        const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
        return `${urlBeforeImParams}${imParams};${queryParams}${remainderOfParams}`;
      }
      const imParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
      return `${urlBeforeImParams}${imParams};${queryParams}`;
    }
    return `${imageUrlToTransform}&im=${queryParams}`;
  };

  if (previewImage) {
    const damDownloadedContentImage = previewImage.damDownloadedContent;
    const damContentSelectorImage = previewImage.damContentSelector?.assetData?.[0];

    if (damDownloadedContentImage?.url) {
      imageUrl = damDownloadedContentImage.url;
    } else {
      imageUrl = damContentSelectorImage?.selectedImageUrl || imageUrl;
    }
  }

  imageUrl = akamaiImageResize(imageUrl);
  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component-name="Resource"
      data-component-position={componentPosition}
      data-component="Resource"
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={cx('resource--grid sui-grid link--hover-no-underline')}
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <Card className="sui-w-full resource-card">
        {video ? (
          <div>
            <VideoPlayerWrap
              videoUrl={video}
              className="sui-w-full"
              componentId={componentId}
            />
          </div>
        ) : (
          <CardMedia
            src={imageUrl}
            aspect="wide"
            alt={altText || title}
            loading={lazyLoad ? 'lazy' : ''}
            data-contentful-entry-id={componentId}
            data-contentful-field-id="previewImage"
          />
        )}
        <CardTitle
          eyebrow={(
            <span
              data-contentful-entry-id={componentId}
              data-contentful-field-id="eyebrow"
            >
              {eyebrow}
            </span>
          )}
          header={(
            <span
              data-contentful-entry-id={componentId}
              data-contentful-field-id="title"
            >
              {title}
            </span>
          )}
        />
        <CardBody>
          <Typography
            height="normal"
            weight="regular"
            variant="body-base"
            color="primary"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="description"
          >
            {description}
          </Typography>
        </CardBody>
        {link && (
          <Button
            href={link}
            variant="text"
            aria-label={`Navigate to ${title}`}
            data-contentful-entry-id={componentId}
            data-contentful-field-id="link"
          >
            Learn More
          </Button>
        )}
      </Card>
    </div>
  );
};
ResourceRenderer.displayName = 'Resource';

ResourceRenderer.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  boldTitle: boolean,
  lazyLoad: boolean,
  dataComponent: shapeType({
    title: string,
    altText: string,
    link: string,
    description: string,
    thumbNailImage: string,
    image: shapeType({
      damDownloadedContent: shapeType({
        url: string
      })
    }),
    video: string,
    previewImage: shapeType({}),
    eyebrow: string
  }),
};

ResourceRenderer.defaultProps = {
  componentPosition: 1,
  componentClass: '',
  boldTitle: false,
  lazyLoad: false,
  dataComponent: {},
};

export { ResourceRenderer };
