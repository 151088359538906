import React, { useEffect, useContext, useState } from 'react';
import { string, object } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import classNames from 'classnames/bind';
import { useHelmet, LinkTag } from '@thd-nucleus/thd-helmet';
import styles from './SponsoredNonEndemic.scss';
import {
  getGAMConfigData,
  getExperienceStyledDefaultBanner,
  placeholderStyleHandler, nonEndemicBanner
} from '../../utils/nonEndemicHelpers';
import { Placeholder } from '~/@thd-olt-component-react/core-ui/dist/core-ui';
import { onTriggerNewRelicAdBlocked } from '../../utils/helpers';
import { newRelicConstants } from '../../constants/newRelicConstants';

const hoistedImgFunction = ({ data }) => {
  const { thdDefaultBannerInfo } = data;
  return [
    new LinkTag({
      rel: 'preload',
      as: 'image',
      href: thdDefaultBannerInfo.imgSrc,
      id: 'spBannerPreloadLink',
    }),
  ];
};

const SponsoredNonEndemic = ({ experienceSlug, contentfulDefaultBanner }) => {
  const cx = classNames.bind(styles);
  const experienceContext = useContext(ExperienceContext);
  const channel = experienceContext.channel === 'desktop' ? 'desktop' : 'mobile';
  const sponsoredContentDefaultBanner = useConfigService('fs-prop:sponsored-default-banners');
  let externalDefaultBanner = {};

  if (sponsoredContentDefaultBanner) {
    try {
      externalDefaultBanner = JSON.parse(sponsoredContentDefaultBanner);
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }
  const gamConfigData = getGAMConfigData({ experienceSlug, channel });
  const thdDefaultBannerInfo = getExperienceStyledDefaultBanner(experienceContext, gamConfigData, externalDefaultBanner, contentfulDefaultBanner);
  const [hasGoogleTag, setHasGoogleTag] = useState(false);

  const data = {
    thdDefaultBannerInfo,
  };

  useHelmet('preloadNonEndmicBanner', { data }, hoistedImgFunction);

  useEffect(
    () => {
      if (!window.googletag?.cmd) {
        let startTime = new Date().getTime();
        nonEndemicBanner(gamConfigData, setHasGoogleTag, startTime);
      } else {
        setHasGoogleTag(true);
      }
    },
    []
  );
  /* istanbul ignore next */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasGoogleTag) {
        const container = document.querySelectorAll('[id^=google_ads_iframe_]')[1];
        if (!container) {
          onTriggerNewRelicAdBlocked(newRelicConstants.NON_ENDEMIC_BANNER);
        }
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [hasGoogleTag]);
  return (
    <>
      {
        !hasGoogleTag
      && (
        <div
          style={{ marginTop: placeholderStyleHandler(experienceSlug, channel).marginTop }}
          data-component="SponsoredNonEndemicPlaceholder"
        >
          <Placeholder
            type="rect"
            height={placeholderStyleHandler(experienceSlug, channel).height}
            width="100%"
          />
        </div>
      )
      }
      {
        hasGoogleTag
        && (
          <div
            id="non-endemic-banner-container"
            className={cx('non-endemic-banner-container ' + experienceSlug.toLowerCase() + '-' + channel)}
            style={thdDefaultBannerInfo.containerStyle}
            data-component="SponsoredNonEndemic"
          >
            <div
              className={cx('non-endemic-banner')}
              id="non-endemic-banner"
              data-testid="non-endemic-banner"
            >
              <a href={thdDefaultBannerInfo.href} aria-label="Default Banner Link">
                <div
                  style={thdDefaultBannerInfo.bgCss}
                />
              </a>
            </div>
          </div>
        )
      }
    </>
  );
};

SponsoredNonEndemic.propTypes = {
  experienceSlug: string,
  contentfulDefaultBanner: object
};

// Including all experience names, comment out for easy testing
SponsoredNonEndemic.defaultProps = {
  experienceSlug: 'move',
  contentfulDefaultBanner: {}

};

export { SponsoredNonEndemic };
