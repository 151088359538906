import React from 'react';
import { bool, number, string, shape } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { akamaiImageResize } from '../utils/akamaiImageOptimizer';
import { getImageDetails } from '../utils/getImageDetails';
import { HeroButton } from './HeroButton';
import { VideoHeroImage } from './VideoHeroImage';
import styles from './story-hero.scss';

const StoryHeroRenderer = ({
  dataComponent, componentId, componentClass, componentPosition, hasSidenav
}) => {

  let {
    colorTheme,
    cta,
    description,
    eyebrow,
    link,
    previewImage,
    title,
    altText
  } = dataComponent || {};

  const { imageUrl, details } = getImageDetails(previewImage);

  const akamaiImageUrl = akamaiImageResize(imageUrl);

  let imageType = '';

  if (imageUrl?.includes('.mp4')) {
    imageType = 'mp4';
  } else if (imageUrl?.includes('.gif')) {
    imageType = 'gif';
  }

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'HeroFlattenImage',
      component: componentClass,
      position: componentPosition,
      type: 'content',
      category: imageType,
    },
  });

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
      className={`hero__impression-wrapper
      sui-h-full
      ${hasSidenav
        ? 'md:sui-max-h-[440px]'
        : '2xl:sui-max-h-[400px] xl:sui-max-h-[350px] lg:sui-max-h-[280px] md:sui-max-h-[210px]'}`}
      data-component="Hero"
    >
      <div
        className="story-hero__card-wrapper sui-relative sui-flex sui-h-full"
        id={`${componentClass}-${componentPosition}-${componentId}`}
        data-component-id={componentId}
        data-component-name={componentClass}
        data-component-position={componentPosition}
      >
        <div className={`story-hero__card sui-bg-white sui-border-1 sui-border-solid sui-border-[#CCCCCB] box-border
          sui-rounded-[0.2rem] sui-m-0 sui-p-0 sui-overflow-hidden sui-flex sui-flex-col md:sui-flex-row-reverse
          sui-justify-between sui-w-full story-hero__card--${colorTheme}`}
        >
          <div className={`story-hero__image-container sui-flex sui-border-b-1 sui-border-b-solid sui-border-b-[#CCCCCB]
            md:sui-sui-border-l-1 md:sui-border-l-solid md:sui-border-l-[#CCCCCB] md:sui-border-b-0 sui-shrink-0
            md:sui-aspect-square lg:sui-aspect-video md:sui-w-[70%] lg:sui-w-3/5 xl:sui-w-7/12 2xl:sui-w-[66%]
          ${hasSidenav
            ? 'sui-max-h-[275px] md:sui-max-h-[440px]'
            : '2xl:sui-max-h-[400px] md:sui-max-h-[210px] lg:sui-max-h-[280px] xl:sui-max-h-[350px]'}`}
          >
            {imageType === 'mp4'
              ? (
                <VideoHeroImage
                  imageUrl={imageUrl}
                  altText={altText}
                  fullWidth
                  videoClasses="sui-w-full sui-object-cover md:sui-aspect-square md:sui-h-full"
                />
              ) : (
                <img
                  src={akamaiImageUrl}
                  alt={`${altText || title}`}
                  title={`Image for ${title}`}
                  height={details?.height}
                  width={details?.width}
                  className="story-hero__image sui-aspect-[inherit] sui-object-cover stretchy"
                  // eslint-disable-next-line react/no-unknown-property
                  fetchpriority="high"
                  data-contentful-field-id="previewImage"
                  data-contentful-entry-id={componentId}
                />
              )}
          </div>
          <div className={`story-hero__info-box sui-flex sui-flex-col sui-justify-center sui-h-full sui-relative sui-p-4
            sui-overflow-y-hidden lg:sui-p-5 md:sui-min-h-[374px] lg:sui-min-h-[384px] xl:sui-min-h-[400px]
            ${!hasSidenav && '2xl:sui-max-h-[400px] md:sui-max-h-[210px] lg:sui-max-h-[280px] xl:sui-max-h-[350px]'}`}
          >
            <div className="story-hero__text-box">
              {eyebrow
                && (
                  <Typography
                    height="tight"
                    weight="bold"
                    variant="body-base"
                    color="brand"
                    data-contentful-field-id="eyebrow"
                    data-contentful-entry-id={componentId}
                  >{eyebrow}
                  </Typography>
                )}
              {title
                && (
                  <Typography
                    height="none"
                    weight="display"
                    variant="h2"
                    color="primary"
                    uppercase
                    data-contentful-field-id="title"
                    data-contentful-entry-id={componentId}
                  >{title}
                  </Typography>
                )}
              {description
                && (
                  <div className="story-hero__text sui-my-4">
                    <Typography
                      height="tight"
                      weight="bold"
                      variant="body-lg"
                      color="primary"
                      data-contentful-field-id="description"
                      data-contentful-entry-id={componentId}
                    >{description}
                    </Typography>
                  </div>
                )}
            </div>
            {link && cta && (
              <HeroButton
                variant={colorTheme}
                href={link}
                componentId={componentId}
              >
                {cta}
              </HeroButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StoryHeroRenderer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentId: string.isRequired,
  componentClass: string.isRequired,
  dataComponent: shape({}),
  componentPosition: number,
  hasSidenav: bool,
};

StoryHeroRenderer.defaultProps = {
  dataComponent: {},
  componentPosition: 1,
  hasSidenav: false,
};

export { StoryHeroRenderer };
