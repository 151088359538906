import React, { useEffect } from 'react';
import {
  arrayOf, string, number, bool, oneOfType, node
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { StoryHero } from './StoryHero';
import { BrandHero } from './BrandHero';

const Hero = ({
  imageUrl,
  pageHref,
  children,
  componentId,
  componentClass,
  componentPosition,
  width,
  height,
  darkMode,
  hasSidenav,
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hero.ready');
  }, []);

  if (componentId && componentClass) {
    return (
      <StoryHero
        componentId={componentId}
        componentClass={componentClass}
        componentPosition={componentPosition}
        darkMode={darkMode}
        hasSidenav={hasSidenav}
      />
    );
  }

  return (
    <div className="hero" data-component="Hero">
      <a href={pageHref}>
        <img className="hero__image sui-w-full" alt="Hero" src={imageUrl} width={width} height={height} />
        <span>{children}</span>
      </a>
    </div>
  );
};

Hero.propTypes = {
  imageUrl: string,
  pageHref: string,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  children: oneOfType([node, arrayOf(node)]),
  componentId: string,
  componentClass: string,
  componentPosition: number,
  darkMode: bool,
  hasSidenav: bool
};

Hero.defaultProps = {
  children: null,
  componentId: '',
  componentClass: '',
  componentPosition: 1,
  imageUrl: '',
  pageHref: '',
  width: null,
  height: null,
  darkMode: false,
  hasSidenav: false
};

Hero.displayName = 'Hero';

Hero.dataModel = extend({}, StoryHero, BrandHero);

export { Hero };
