import React from 'react';
import { bool, shape, string } from 'prop-types';
import { NewspaperRenderer } from './NewspaperRenderer';
import { NewspaperModule } from './NewspaperModule';

const NewspaperPreview = ({ componentId, componentClass, lazyLoad, livePreviewData }) => {

  return (
    livePreviewData
      ? (
        <NewspaperRenderer
          componentId={componentId}
          componentClass={componentClass}
          lazyLoad={lazyLoad}
          dataComponent={livePreviewData}
          richText={livePreviewData?.description?.json}
          isLivePreview
        />
      )
      : (
        <NewspaperModule
          componentId={componentId}
          componentClass={componentClass}
          lazyLoad={lazyLoad}
        />
      )
  );
};

NewspaperPreview.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  lazyLoad: bool,
  livePreviewData: shape({
    id: string,
    altText: string,
    link: string,
    description: string,
    damImageAlignmentBehavior: string,
    damImageWidthBehavior: string,
    descriptionAlignment: string,
    desktopImage: {},
    tabletImage: {},
    mobileImage: {}
  })
};

NewspaperPreview.defaultProps = {
  lazyLoad: false,
  livePreviewData: null
};

NewspaperPreview.displayName = 'NewspaperPreview';

export { NewspaperPreview };