import React from 'react';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';

const xlSlides = (slides) => slides?.map((group, groupKey) => (
  <div className="sui-flex sui-gap-y-4 sui-flex-col" key={groupKey}>
    {group?.map((card, cardKey) => (
      <div className="capability_card" key={cardKey + card.id}>
        <CapabilityCard
          headline={card.headline}
          richText={
            typeof card.richTextContent === 'string'
              ? card.richTextContent
              : JSON.stringify(card.richTextContent?.json)
          }
          componentPosition={cardKey + 1}
          componentId={card.id || card.sys?.id}
        />
      </div>
    ))}
  </div>
)
);

export default xlSlides;
