import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, useBreakpoint } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { ImpressionProvider } from '@thd-olt-component-react/impression';

import xlSlides from './slides/XlSlides';
import lgSlides from './slides/LgSlides';
import mdSlides from './slides/MdSlides';
import smSlides from './slides/SmSlides';

import { chunkArray } from '../utils';
import './callout_card.scss';

const numberOfCards = {
  xl: 4,
  lg: 3,
  md: 4,
  sm: 2
};

const containerLayoutStyles = {
  xl: 'sui-grid-cols-2 sui-gap-y-0 sui-gap-x-8',
  lg: 'sui-grid-cols-2 sui-gap-y-0 sui-gap-x-6',
  md: 'sui-grid-cols-1 sui-gap-y-6 sui-gap-x-0',
  sm: 'sui-grid-cols-1 sui-gap-y-4 sui-gap-x-0'
};

const CalloutCardRenderer = ({
  componentId,
  componentClass,
  size,
  dataComponent,
  capabilityCardContainer,
  displayName
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('callout_card.ready');
  }, []);

  const sm = useBreakpoint('sm');
  const md = useBreakpoint('md');
  const lg = useBreakpoint('lg');

  let imageUrl;
  const hasImage = !!dataComponent?.previewImage;
  const altText = dataComponent?.altText;
  const title = dataComponent?.title;
  const description = dataComponent?.description;

  if (hasImage) {
    const damDownloadedContentImage = dataComponent?.previewImage?.damDownloadedContent;
    const damContentSelectorImage = dataComponent?.previewImage?.damContentSelector?.assetData?.[0];

    imageUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl || imageUrl;
  }

  function getCardsNumberPerSlide() {
    if (size) return numberOfCards[size];

    if (sm.exact || sm.lesser) return numberOfCards.sm;

    if ((sm.greater && md.lesser) || md.exact) return numberOfCards.md;

    if ((md.greater && lg.lesser) || lg.exact) return numberOfCards.lg;

    return numberOfCards.xl;
  }

  function getSlidesComponent(slides) {
    if (size) {
      switch (size) {
      case 'xl':
        return xlSlides(slides);
      case 'lg':
        return lgSlides(slides);
      case 'md':
        return mdSlides(slides);
      case 'sm':
        return smSlides(slides);
      default:
        break;
      }
    }

    if (sm.exact || sm.lesser) return smSlides(slides);

    if ((sm.greater && md.lesser) || md.exact) return mdSlides(slides);

    if ((md.greater && lg.lesser) || lg.exact) return lgSlides(slides);

    return xlSlides(slides);
  }

  function getContainerLayoutStyles() {
    if (size) return containerLayoutStyles[size];

    if (sm.exact || sm.lesser) return containerLayoutStyles.sm;

    if ((sm.greater && md.lesser) || md.exact) return containerLayoutStyles.md;

    if ((md.greater && lg.lesser) || lg.exact) return containerLayoutStyles.lg;

    return containerLayoutStyles.xl;
  }

  function getAspectRatio() {
    if (size) {
      if (size === 'sm' || size === 'md') return 'sui-aspect-video';

      return 'sui-aspect-square';
    }

    if (md.exact || md.lesser) return 'sui-aspect-video';

    return 'sui-aspect-square';
  }

  const chunkedCards = chunkArray(capabilityCardContainer, getCardsNumberPerSlide());

  const impressionData = {
    id: componentId,
    component: displayName,
    name: displayName,
    type: 'content'
  };
  return (
    <div className="callout_card" data-component="CalloutCard">
      <div className="sui-space-y-2">
        <Typography
          variant="h2"
          weight="display"
          data-contentful-entry-id={componentId}
          data-contentful-field-id="title"
        >
          {title}
        </Typography>
        {description && (
          <Typography
            variant="body-base"
            color="subtle"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="description"
          >{description}
          </Typography>
        )}
      </div>
      <div className={'callout_card__container ' + getContainerLayoutStyles()}>
        <div
          className="callout_image"
          data-contentful-entry-id={componentId}
          data-contentful-field-id="previewImage"
        >
          <div className={'sui-w-full sui-object-contain ' + getAspectRatio()}>
            <img
              src={imageUrl}
              className="sui-h-full sui-object-contain sui-aspect-square sui-mx-auto"
              alt={`${altText || title}`}
              title={`${altText || title}`}
              width="auto"
              height="auto"
            />
          </div>
        </div>
        <div
          className="callout_carousel"
          data-contentful-entry-id={componentId}
          data-contentful-field-id="capabilityCardContainer"
        >

          <ImpressionProvider data={impressionData}>
            {!!chunkedCards?.length && (
              <Carousel
                disableShadow
                disableMargin
                hideControls={chunkedCards?.length <= 1}
                slidesPerView={1}
                slidesPerGroup={1}
                breakpoints={{
                  sm: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  md: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  lg: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  xl: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  }
                }}
              >
                {chunkedCards ? getSlidesComponent(chunkedCards) : []}
              </Carousel>
            )}
          </ImpressionProvider>
        </div>
      </div>
    </div>
  );

};

CalloutCardRenderer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired,
  size: PropTypes.string,
  dataComponent: PropTypes.shape({
    sys: PropTypes.shape({
      id: PropTypes.string
    }),
    previewImage: PropTypes.shape({
      damContentSelector: PropTypes.shape({
        assetData: PropTypes.arrayOf(
          PropTypes.shape({
            selectedImageUrl: PropTypes.string
          })
        )
      }),
      damDownloadedContent: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    altText: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  capabilityCardContainer: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    richTextContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
  })),
  displayName: PropTypes.string,
};

CalloutCardRenderer.defaultProps = {
  size: null,
  dataComponent: null,
  capabilityCardContainer: null,
  displayName: 'CalloutCard',
};

export { CalloutCardRenderer };
