import React, { useEffect } from 'react';
import { string, number, bool } from 'prop-types';
import {
  params,
  shape,
  namedFragment,
  customType,
  string as stringType,
  number as numberType,
  useDataModel,
  typename,
  arrayOf
} from '@thd-nucleus/data-sources';
import { StoryHeroRenderer } from './StoryHeroRenderer';

const StoryHero = ({
  componentId, componentClass, componentPosition, hasSidenav
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('contentfulhero.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
    skip: !(componentId && componentClass),
  });

  return (
    <StoryHeroRenderer
      dataComponent={data?.component}
      componentId={componentId}
      componentClass={componentClass}
      componentPosition={componentPosition}
      hasSidenav={hasSidenav}
    />
  );
};

StoryHero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  hasSidenav: bool
};

StoryHero.defaultProps = {
  componentPosition: 1,
  hasSidenav: false
};

StoryHero.displayName = 'Contentful Story Hero';

StoryHero.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Hero']).isRequired(),
  }).shape({
    Hero: namedFragment({ inline: true, fragmentType: 'Hero' }).shape({
      componentName: stringType(),
      colorTheme: stringType(),
      cta: stringType(),
      description: stringType(),
      eyebrow: stringType(),
      id: stringType(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
      link: stringType(),
      title: stringType(),
      altText: stringType(),
      __typename: typename('Hero')
    })
  })
};

export { StoryHero };
