import React from 'react';

export const getImageDetails = (previewImage) => {
  let details;
  let imageUrl;

  let damDownloadedContentImage = previewImage?.damDownloadedContent;
  if (damDownloadedContentImage?.url && damDownloadedContentImage?.details) {
    imageUrl = damDownloadedContentImage?.url;
    details = damDownloadedContentImage?.details;
  } else {
    let damContentSelectorImage = previewImage?.damContentSelector?.assetData?.[0];
    let imageDimensions = damContentSelectorImage?.selectedImageDimensions;
    if (imageDimensions && damContentSelectorImage?.selectedImageUrl) {
      const isNumeric = (stringNum) => {
        return !Number.isNaN(stringNum) && !Number.isNaN(parseFloat(stringNum));
      };
        /*
        TODO: Note: Having to parse through a string to get the dimensions is temporary until a more permanent solution
        TODO: is put in place. The assumption here is that the format of the string will always be 'width/height'.
        */
      let cleansedImageDimensions = imageDimensions.replace(/[^0-9/]/g, '');
      let parsedImageDimensions = cleansedImageDimensions.split('/');
      const imageHeight = parsedImageDimensions[1];
      const imageWidth = parsedImageDimensions[0];
      if (isNumeric(imageHeight) && isNumeric(imageWidth)) {
        imageUrl = damContentSelectorImage?.selectedImageUrl;
        details = {
          height: imageHeight,
          width: imageWidth
        };
      }
    }
  }
  return (
    { imageUrl, details }
  );

};