/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { string as stringType, shape as shapeType } from 'prop-types';
import { MedioComposableBannerRenderer } from './MedioComposableBannerRenderer';
import { MedioComposableBanner } from './MedioComposableBanner';

const MedioComposableBannerPreview = ({ componentId, componentClass, livePreviewData }) => {
  return (
    livePreviewData ? (
      <MedioComposableBannerRenderer
        data={livePreviewData}
        componentId={componentId}
        componentClass={componentClass}
      />
    ) : (
      <MedioComposableBanner
        componentId={componentId}
        componentClass={componentClass}
      />
    )
  );
};

MedioComposableBannerPreview.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  livePreviewData: shapeType({})
};

MedioComposableBannerPreview.defaultProps = {
  componentClass: '',
  livePreviewData: null
};

MedioComposableBannerPreview.displayName = 'MedioComposableBannerPreview';

export { MedioComposableBannerPreview };
