export const isHyperlinkInternal = (uri) => {
  return String(uri).includes('homedepot.com');
};

export const getVariant = (nodeType) => {
  switch (nodeType) {
  case 'heading-1':
    return 'h1';
  case 'heading-2':
    return 'h2';
  case 'heading-3':
    return 'h3';
  case 'heading-4':
    return 'h4';
  case 'heading-5':
    return 'h5';
  case 'heading-6':
    return 'h6';
  default:
    return 'body-base';
  }
};

export const getMarks = (marks) => {
  if (marks && marks.length > 0) {
    const marksList = marks.map((mark) => mark.type); // array of strings
    return {
      isBold: marksList.includes('bold'),
      isUnderline: marksList.includes('underline'),
      isItalic: marksList.includes('italic'),
      isSub: marksList.includes('subscript'),
      isSup: marksList.includes('superscript'),
      isCode: marksList.includes('code')
    };
  }
  return {
    isBold: false,
    isUnderline: false,
    isItalic: false,
    isSub: false,
    isSup: false,
    isCode: false
  };
};

export const getDetailedVariant = (item, isBigText, mainVariant) => {
  const isSubOrSup = getMarks(item.marks).isSub || getMarks(item.marks).isSup;
  if (isSubOrSup && isBigText) {
    return 'body-small';
  }
  if (isSubOrSup && !isBigText) {
    return 'body-xs';
  }
  return mainVariant;
};

export const generateKey = (text, index, variant) => {
  const keySuffix = `-key-${variant}-${index}`;
  if (text && typeof text === 'string') {
    const removeWhitespace = text.trim()
      .replace(/\s+/g, '');
    if (removeWhitespace.length > 6) {
      return `${removeWhitespace.substring(removeWhitespace.length - 4)}
      ${removeWhitespace.substring(0, 3)}${keySuffix}`;
    }
    if (removeWhitespace.length !== 0 && removeWhitespace.length < 6) {
      return `${removeWhitespace}${keySuffix}`;
    }
    return `no-text${keySuffix}`;
  }
  return `no-text${keySuffix}`;
};

export const generateNestedKey = (node, index, variant) => {
  if (node.content && node.content.length > 0) {
    if (node.content[0].nodeType === 'text') {
      if (node.content[0].value) {
        return generateKey(node.content[0].value, index, variant);
      }
      return `key-${index}-${variant}`;
    }
    return generateNestedKey(node.content[0], index, variant);
  }
  return `key-${index}-${variant}`;
};

const getCellsList = (node, type) => {
  if (node.content && node.content.length > 0) {
    if (node.nodeType === type) {
      return node.content.map((content) => {
        return content;
      });
    }
    const mainList = node.content.map((content) => {
      return getCellsList(content, type);
    });
    return mainList.filter((header) => header.length > 0);
  }
  return [];
};

const cleanList = (list) => {
  if (list.length > 0) {
    return list.map((item) => {
      if (item.length > 1) {
        return item;
      }
      return item[0];
    });
  }
  return [];
};

export const getHeaders = (node) => {
  const headersList = getCellsList(node, 'table-header-cell');
  if (headersList.length === 1) {
    const mainArray = headersList[0];
    return cleanList(mainArray);
  }
  return [];
};

export const getRows = (node) => {
  const rowsList = getCellsList(node, 'table-cell');
  return rowsList.map((item) => {
    return cleanList(item);
  });
};
