import React from 'react';
import classNames from 'classnames/bind';
import { node, string } from 'prop-types';
import { RenderResolver } from './RenderResolver';
import styles from '../inspirational-layout.scss';

/**
 * This component is a shorthand for the RenderResolver component. It is specifically for the inspirational layout.
 *
 * @param {string} componentId - The unique identifier for the child component.
 * @param {node} children - The children components.
 * @returns {JSX.Element} The rendered component.
 */
export const InspirationalRenderResolver = ({ children, componentId }) => {
  const cx = classNames.bind(styles);

  return (
    <RenderResolver componentId={componentId} key={componentId} pageType="inspirationalLayout" templateSectionStyle={cx('inspirational-layout__static-section')}>
      {children}
    </RenderResolver>
  );
};

InspirationalRenderResolver.propTypes = {
  children: node,
  componentId: string.isRequired,
};

InspirationalRenderResolver.defaultProps = {
  children: null,
};
