import { parse, stringify } from 'query-string';

function hasError(error, code) {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length) return false;
  return !!error.graphQLErrors.find((err) => err.extensions && err.extensions.id === code);
}

export const HTTP_CODE = {
  ERROR: 500,
  NOT_FOUND: 404,
  TIMEOUT: 408,
  OK: 200,
  PERM_REDIRECT: 301,
  TEMP_REDIRECT: 302
};

export const handleErrors = (error) => {
  if (hasError(error, HTTP_CODE.NOT_FOUND)) {
    return {
      error,
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND
    };
  }

  if (hasError(error, HTTP_CODE.ERROR)) {
    return {
      error,
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.ERROR
    };
  }

  return null;
};

export const getPathWithMergedParams = (redirectPath, queryParams) => {
  let parsedQueryParms = parse(queryParams);
  const splitRedirectPath = redirectPath.split('?');
  let path = splitRedirectPath[0];
  if (splitRedirectPath.length >= 2) {
    const redirectPathParams = splitRedirectPath[1];
    const parsedRedirectQueryParms = parse(redirectPathParams);
    Object.assign(parsedQueryParms, parsedRedirectQueryParms);
  }
  return path + '?' + stringify(parsedQueryParms);
};

export const getQueryStringValue = (queryParams, key) => {
  /* Not supported by IE */
  if (!URLSearchParams || !queryParams) {
    return '';
  }
  const params = new URLSearchParams(queryParams);

  if (!params.has(key)) {
    return '';
  }

  return params.get(key);
};

export const setUrlContext = (path, urlContext) => {
  let newPath = '';
  if (path && !path?.startsWith(urlContext)) {
    newPath = path.substring(path.indexOf(urlContext));
    return newPath;
  }
  return path;
};
