import { getDefaultBanner, triggerNewRelic, triggerNewRelicTimeLog } from './helpers';
import { DIMENSIONS, MARGINTOP } from '../constants/dimensions';
import { newRelicConstants } from '../constants';

const GAMConstants = {
  MOVE: 'move',
  MOVING_PREPARATION: 'moving-preparation',
  MOVING_DAY: 'moving-day',
  NEW_HOME_ESSENTIALS: 'new-home-essentials',
  CONTENT: 'content',
  ALP: 'alp',
  DIY_PROJECTS_AND_IDEAS: 'diy_projects_and_ideas'
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getGAMConfigData = ({ experienceSlug, channel }) => {
  switch (experienceSlug) {
  case GAMConstants.MOVE:
  case GAMConstants.MOVING_DAY:
  case GAMConstants.MOVING_PREPARATION:
  case GAMConstants.NEW_HOME_ESSENTIALS:
    return {
      label: `${experienceSlug}-${channel}`,
      adUnitPath: `/22299932394/Movers-Non-Endemic-Native-${capitalizeFirstLetter(channel)}`,
      maxBannerDimensions: channel === 'mobile'
        ? [DIMENSIONS.GAM.MOVE.WIDTH.MOBILE,
          DIMENSIONS.GAM.MOVE.HEIGHT.MOBILE]
        : [DIMENSIONS.GAM.MOVE.WIDTH.DESKTOP,
          DIMENSIONS.GAM.MOVE.HEIGHT.DESKTOP],
      adAssetDimensions: ['fluid'],
      css: { marginTop: `${MARGINTOP.GAM.MOVE}px` }
    };
  case GAMConstants.ALP:
  case GAMConstants.DIY_PROJECTS_AND_IDEAS:
  case GAMConstants.CONTENT:
    return {
      label: `${experienceSlug}-${channel}`,
      adUnitPath: `/22299932394/DIY-Landing-${capitalizeFirstLetter(channel)}`,
      maxBannerDimensions: channel === 'mobile'
        ? [DIMENSIONS.GAM.DIY.WIDTH.MOBILE,
          DIMENSIONS.GAM.DIY.HEIGHT.MOBILE]
        : [DIMENSIONS.GAM.DIY.WIDTH.DESKTOP,
          DIMENSIONS.GAM.DIY.HEIGHT.DESKTOP],
      adAssetDimensions: ['fluid'],
      css: { margin: '0px auto', overflow: 'hidden' }
    };
  default:
    return {
      label: `default-${channel}`,
      adUnitPath: '/6075/gvarelal_dfptest/gvarelal_trainingseries',
      maxBannerDimensions: [DIMENSIONS.GAM.DEFAULT.WIDTH, DIMENSIONS.GAM.DEFAULT.HEIGHT],
      adAssetDimensions: [DIMENSIONS.GAM.DEFAULT.WIDTH, DIMENSIONS.GAM.DEFAULT.HEIGHT],
      css: { }
    };
  }
};

const getExperienceStyledDefaultBanner = (experienceContext, gamConfigData, externalDefaultBanner, contentfulDefaultBanner) => {
  let standardDefaultBanner = getDefaultBanner(experienceContext, externalDefaultBanner, contentfulDefaultBanner);
  const { maxBannerDimensions, css = {} } = gamConfigData;
  const containerStyle = {
    maxWidth: `${maxBannerDimensions[0]}px`,
    height: `${maxBannerDimensions[1]}px`,
    ...css
  };
  standardDefaultBanner = { ...standardDefaultBanner, containerStyle };
  standardDefaultBanner.bgCss.maxWidth = `${maxBannerDimensions[0]}px`;
  standardDefaultBanner.bgCss.height = `${maxBannerDimensions[1]}px`;

  return standardDefaultBanner;
};

function addScriptTag(url) {

  if (!window.googletag) {
    const gamScript = document.createElement('script');
    gamScript.async = true;
    gamScript.src = url;

    const firstScript = document.getElementsByTagName('body')[0];
    firstScript.parentNode.insertBefore(gamScript, firstScript);
  }
}

const placeholderStyleHandler = (experienceSlug, channel) => {
  switch (experienceSlug) {
  case GAMConstants.MOVE:
  case GAMConstants.MOVING_PREPARATION:
  case GAMConstants.MOVING_DAY:
  case GAMConstants.NEW_HOME_ESSENTIALS:
    return { height: channel === 'mobile'
      ? `${DIMENSIONS.GAM.MOVE.HEIGHT.MOBILE}px`
      : `${DIMENSIONS.GAM.MOVE.HEIGHT.DESKTOP}px`,
    marginTop: `${MARGINTOP.GAM.MOVE}px` };
  case GAMConstants.ALP:
  case GAMConstants.DIY_PROJECTS_AND_IDEAS:
  case GAMConstants.CONTENT:
    return { height: channel === 'mobile'
      ? `${DIMENSIONS.GAM.DIY.HEIGHT.MOBILE}px`
      : `${DIMENSIONS.GAM.DIY.HEIGHT.DESKTOP}px`,
    marginTop: `${MARGINTOP.GAM.DEFAULT}px` };
  default:
    return { height: `${DIMENSIONS.GAM.DEFAULT.HEIGHT}px`,
      marginTop: `${MARGINTOP.GAM.DEFAULT}px` };
  }
};

const gamNewRelicEvent = (event, listenerType, targetSlot, startTime) => {
  const slot = event.slot;
  let endTime = new Date().getTime();
  if (slot === targetSlot) {
    if (listenerType === 'impression') {
      triggerNewRelic(newRelicConstants.NON_ENDEMIC_BANNER, newRelicConstants.IMPRESSION);
    } else if (listenerType === 'requestTime') {
      triggerNewRelicTimeLog(newRelicConstants.NON_ENDEMIC_BANNER_REQUEST, startTime, endTime);
    } else if (listenerType === 'adLoadTime') {
      triggerNewRelicTimeLog(newRelicConstants.NON_ENDEMIC_BANNER_AD_LOAD, startTime, endTime);
    }
  }
};

const nonEndemicBanner = (gamConfigData, setHasGoogleTag, startTime) => {

  addScriptTag('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
  window.googletag = window.googletag || { cmd: [] };
  triggerNewRelic(newRelicConstants.NON_ENDEMIC_BANNER, newRelicConstants.LOAD);

  /* istanbul ignore next */
  window.googletag.cmd.push(function () {
    const targetSlot = window.googletag
      .defineSlot(
        gamConfigData.adUnitPath,
        gamConfigData.adAssetDimensions,
        'non-endemic-banner',
      );

    targetSlot.addService(window.googletag.pubads());
    window.googletag.enableServices();
    window.googletag.pubads().setCentering(true);

    window.googletag.cmd.push(function () {
      window.googletag.display('non-endemic-banner');
    });

    // event for impression fired
    window.googletag.pubads().addEventListener('impressionViewable', (event) => {
      gamNewRelicEvent(event, 'impression', targetSlot);
    });

    // event for ad request is complete
    window.googletag.pubads().addEventListener('slotRequested', (event) => {
      gamNewRelicEvent(event, 'requestTime', targetSlot, startTime);
    });

    // event for iframe load
    window.googletag.pubads().addEventListener('slotOnload', (event) => {
      gamNewRelicEvent(event, 'adLoadTime', targetSlot, startTime);
    });

  });

  setHasGoogleTag(true);
};

function onShouldRenderSponsoredMiddleBanner(slugId) {

  if (!slugId) return false;
  let sanitizedSlugId = '';

  // we need to take into account preview links which append -b2c or -b2b
  if (slugId.endsWith('b2c') || slugId.endsWith('b2b')) {
    sanitizedSlugId = slugId?.substr(0, slugId?.lastIndexOf('-'));
  } else {
    sanitizedSlugId = slugId;
  }

  const enabledSponsoredMiddleBannerPages = ['move', 'moving-day', 'moving-preparation', 'new-home-essentials', 'diy_projects_and_ideas'];
  return enabledSponsoredMiddleBannerPages.includes(sanitizedSlugId);

}
export {
  getGAMConfigData, getExperienceStyledDefaultBanner,
  onShouldRenderSponsoredMiddleBanner,
  placeholderStyleHandler, gamNewRelicEvent,
  nonEndemicBanner, addScriptTag
};
