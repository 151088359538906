import React, { useEffect, useState, useContext } from 'react';
import { string, object } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { isNValueRequiredAndValid } from '../../utils/helpers';
import { SponsoredBanner, } from '../SponsoredBanner';
import { SponsoredSkyscraperBanner } from '../SponsoredSkyscraperBanner';
import { SponsoredNonEndemic } from '../SponsoredNonEndemic';
import { cleanString } from '../../utils/utils';
import { onShouldRenderSponsoredMiddleBanner } from '../../utils/nonEndemicHelpers';

const BasicSponsoredBanner = ({
  browserId, customRenderId, schema, slugId, sponsoredConfig, contentfulDefaultBanner
}) => {

  const experienceContext = useContext(ExperienceContext);
  const formattedCustomRenderId = cleanString(customRenderId);

  const keyProp = sponsoredConfig
    && Object.keys(sponsoredConfig || {}).find((key) => cleanString(key).includes(formattedCustomRenderId));

  // if there is no slugId, find the appropriate config from customRenderId
  const newSlugId = slugId
    || keyProp?.split(customRenderId)[1]?.slice(1)
    || keyProp?.split(formattedCustomRenderId)[1]?.slice(1) || '';

  // This is needed if there is no slugID passed
  const key = `${customRenderId}-${newSlugId}`;
  const configKey = sponsoredConfig
    && Object.keys(sponsoredConfig)?.find((cKey) => cleanString(cKey) === cleanString(key));
  const configObj = configKey && sponsoredConfig[configKey];

  const [component, setComponent] = useState(null);

  const bannerToDisplay = () => {

    const bannerConfig = {
      sponsoredtopbanner: SponsoredBanner,
      sponsoredmiddlebanner: SponsoredNonEndemic,
      sponsoredskyscraperbanner: SponsoredSkyscraperBanner
    };

    // uncomment when ready for rehub
    if (!configObj) return null;

    if (!isNValueRequiredAndValid(slugId, customRenderId, schema)) return null;

    const { activeAdType } = configObj;
    let activeConfig = configObj?.adConfigs[activeAdType];
    if (activeConfig) {
      let bannerConfigToUse = formattedCustomRenderId;

      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('adServer')) {
        if (urlParams.get('adServer') === 'gam') {
          bannerConfigToUse = 'sponsoredmiddlebanner';
        } else if (urlParams.get('adServer') === 'piq') {
          bannerConfigToUse = 'sponsoredtopbanner';
        }
      }

      let Component = bannerConfig[bannerConfigToUse];

      if (activeAdType === 'gam') {
        activeConfig = activeConfig[experienceContext?.channel];
      }
      if (Component) {
        return (
          <Component {...{
            ...activeConfig,
            experienceSlug: newSlugId,
            schema,
            browserId
          }
          }
          />
        );
      }
      return null;
    }
    return null;

  };

  const renderDefaultBanner = () => {
    const defaultProps = {
      browserId,
      customRenderId,
      schema,
      slugId: newSlugId,
    };

    switch (cleanString(customRenderId)) {

    case 'sponsoredtopbanner':

      return (
        <SponsoredBanner
          {...defaultProps}
          contentfulDefaultBanner={contentfulDefaultBanner}
        />
      );
    case 'sponsoredmiddlebanner':
      if (onShouldRenderSponsoredMiddleBanner(slugId)) {
        return (
          <SponsoredNonEndemic
            experienceSlug={slugId}
            contentfulDefaultBanner={contentfulDefaultBanner}
          />
        );
      }
      return (
        <SponsoredBanner
          showSponsoredBanner={false} // renders default banner if slugId isn't move
          {...defaultProps}
          contentfulDefaultBanner={contentfulDefaultBanner}
        />
      );
    case 'sponsoredskyscraperbanner':
      return (
        <SponsoredSkyscraperBanner {...defaultProps} />
      );

    default:
      return null;
    }
  };

  const getBannerToDisplay = () => {
    const bannerToRender = bannerToDisplay();

    if (bannerToRender) {
      setComponent(bannerToRender);
    }
  };

  useEffect(() => {
    getBannerToDisplay();
  }, []);

  if (component) {
    return (
      <div id="basic-sponsored-banner" data-testid="basic-sponsored-banner">
        {component}
      </div>
    );
  }
  return (
    <div id="basic-sponsored-banner" data-testid="basic-sponsored-banner">
      {renderDefaultBanner()}
    </div>
  );
};

BasicSponsoredBanner.propTypes = {
  browserId: string,
  customRenderId: string,
  schema: string,
  slugId: string,
  sponsoredConfig: object,
  contentfulDefaultBanner: object,
};

BasicSponsoredBanner.defaultProps = {
  browserId: '',
  customRenderId: '',
  schema: 'cat_sponsored',
  slugId: '',
  sponsoredConfig: {},
  contentfulDefaultBanner: {},
};

export { BasicSponsoredBanner };
